import { LegUnpricedSchema } from '@lyra/core/api/types/private.send_rfq'

import { ParsedOption } from '../utils/instruments'

export type LegData = {
  leg: LegUnpricedSchema
  parsed: ParsedOption
}

export enum OptionsStrategy {
  ShortIronCondor = 'ShortIronCondor',
  LongIronCondor = 'LongIronCondor',

  ShortPutCondor = 'ShortPutCondor',
  LongPutCondor = 'LongPutCondor',

  ShortCallCondor = 'ShortCallCondor',
  LongCallCondor = 'LongCallCondor',

  ShortIronButterfly = 'ShortIronButterfly',
  LongIronButterfly = 'LongIronButterfly',

  ShortPutButterfly = 'ShortPutButterfly',
  LongPutButterfly = 'LongPutButterfly',

  ShortCallButterfly = 'ShortCallButterfly',
  LongCallButterfly = 'LongCallButterfly',

  PutDebit = 'PutDebit',
  PutCredit = 'PutCredit',
  CallDebit = 'CallDebit',
  CallCredit = 'CallCredit',

  LongStraddle = 'LongStraddle',
  LongStrangle = 'LongStrangle',

  PutRatioBack = 'PutRatioBack',
  PutRatioFront = 'PutRatioFront',
  CallRatioBack = 'CallRatioBack',

  ShortCall = 'ShortCall',
  LongCall = 'LongCall',
  ShortPut = 'ShortPut',
  LongPut = 'LongPut',

  Ratio = 'Ratio',

  Custom = 'Custom',
}

// build a "Strategy Key" which is C/D (credit/debit): BC/BP/SC/SP.. (buy/sell call/put)
export function getStrategyKey(legs: LegData[], isCredit: boolean): string {
  return (
    (isCredit ? 'C:' : 'D:') +
    legs.reduce(
      (acc, leg) =>
        acc + `${leg.leg.direction === 'buy' ? 'B' : 'S'}${leg.parsed.isCall ? 'C' : 'P'}`,
      ''
    )
  )
}

// strategy dicts have mapped "Strategy Key" which can be used to access the strategy name
// other conditions are enforced in getTwoLegStrategy, getThreeLegStrategy, getFourLegStrategy
// to determine if the conditions are met, check if the debit/credit:buy/sell..call/put matches

export const condorStrategies: Record<string, OptionsStrategy> = {
  'C:BPSPSCBC': OptionsStrategy.ShortIronCondor,
  'D:SPBPBCSC': OptionsStrategy.LongIronCondor,
  'C:SPBPBPSP': OptionsStrategy.ShortPutCondor,
  'D:BPSPSPBP': OptionsStrategy.LongPutCondor,
  'C:SCBCBCSC': OptionsStrategy.ShortCallCondor,
  'D:BCSCSCBC': OptionsStrategy.LongCallCondor,
}

export const butterflyStrategies: Record<string, OptionsStrategy> = {
  'C:BPSCSPBC': OptionsStrategy.ShortIronButterfly,
  'D:SPBCBPSC': OptionsStrategy.LongIronButterfly,

  'C:SCBCSC': OptionsStrategy.ShortCallButterfly,
  'D:BCSCBC': OptionsStrategy.LongCallButterfly,
  'C:SPBPSP': OptionsStrategy.ShortPutButterfly,
  'D:BPSPBP': OptionsStrategy.LongPutButterfly,
}

export const basicTwoLegStrategies: Record<string, OptionsStrategy> = {
  'D:SPBP': OptionsStrategy.PutDebit,
  'C:BPSP': OptionsStrategy.PutCredit,
  'D:BCSC': OptionsStrategy.CallDebit,
  'C:SCBC': OptionsStrategy.CallCredit,
}
