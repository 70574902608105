import { PlusSquare } from '@lyra/core/components/Icon'
import Section from '@lyra/core/components/Section'
import { QuerySpotOrderType } from '@lyra/web/constants/query'
import { LegsUnpriced } from '@lyra/web/constants/rfqs'
import useOptionsOrderTypeQueryParam from '@lyra/web/hooks/useOptionsOrderTypeQueryParam'
import useSelectedOptions from '@lyra/web/hooks/useSelectedOptions'
import useSendRfqQueryParams from '@lyra/web/hooks/useSendRfqQueryParams'
import useTickers from '@lyra/web/hooks/useTickers'
import useTradeSizeInput from '@lyra/web/hooks/useTradeSizeInput'
import { getMarketId } from '@lyra/web/utils/markets'
import { useMemo } from 'react'

import TradeSubaccountSection from '../TradeSubaccountSection'
import OptionsOrderbookTradeForm from './OptionsOrderbookTradeForm'
import OptionsRfqTradeForm from './OptionsRfqTradeForm'
import OptionsTradeFormContainer from './OptionsTradeFormContainer'

const EMPTY_SECTION_HEIGHT = 360

export default function OptionsTradeForm() {
  const { selectedOptions, deselectOption, clearOptions } = useSelectedOptions()

  const [orderType, setOrderType] = useOptionsOrderTypeQueryParam()

  // TODO: @earthtojake consolidate rfq query params with orderbook query params
  const [sendRfqParams] = useSendRfqQueryParams()
  const [orderbookSize] = useTradeSizeInput()
  const legs: LegsUnpriced = useMemo(
    () =>
      orderType === QuerySpotOrderType.Rfq
        ? sendRfqParams.legs
        : selectedOptions.length > 0
        ? [
            {
              amount: orderbookSize.toString(),
              instrument_name: selectedOptions[0].instrumentName,
              direction: selectedOptions[0].isBuy ? 'buy' : 'sell',
            },
          ]
        : [],
    [orderType, sendRfqParams, orderbookSize, selectedOptions]
  )

  const tickers = useTickers(legs.map((leg) => leg.instrument_name))

  const firstTicker = tickers ? tickers[Object.keys(tickers)[0]] : null
  const marketId = firstTicker ? getMarketId(firstTicker) : null

  return selectedOptions.length > 0 ? (
    firstTicker && tickers && marketId ? (
      <>
        <TradeSubaccountSection />
        <OptionsTradeFormContainer
          tickers={tickers}
          legs={legs}
          orderType={orderType}
          onChangeOrderType={setOrderType}
          onClose={clearOptions}
        >
          {orderType === QuerySpotOrderType.Rfq ? (
            <OptionsRfqTradeForm marketId={marketId} />
          ) : (
            <OptionsOrderbookTradeForm
              selectedOption={selectedOptions[0]}
              ticker={firstTicker}
              marketId={marketId}
              onSubmitOrder={clearOptions}
              onDeselect={deselectOption}
            />
          )}
        </OptionsTradeFormContainer>
      </>
    ) : (
      <>
        <TradeSubaccountSection showBottomBorder />
        <Section noTopBorder height={EMPTY_SECTION_HEIGHT}>
          <Section.Spinner height="100%" />
        </Section>
      </>
    )
  ) : (
    <>
      <TradeSubaccountSection showBottomBorder />
      <Section noTopBorder height={EMPTY_SECTION_HEIGHT}>
        <Section.Subheader title="Select an option" />
        <Section.YStack flexGrow={1} justifyContent="center" alignItems="center">
          <PlusSquare width={64} height={64} strokeWidth={0.5} color="secondary" />
        </Section.YStack>
      </Section>
    </>
  )
}
