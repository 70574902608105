import BodyText from '@lyra/core/components/BodyText'
import Countdown from '@lyra/core/components/Countdown'
import LabelText from '@lyra/core/components/LabelText'
import Link from '@lyra/core/components/Link'
import Section from '@lyra/core/components/Section'
import TooltipText from '@lyra/core/components/TooltipText'
import formatUSD from '@lyra/core/utils/formatUSD'
import CopyButton from '@lyra/web/components/common/CopyButton'
import { isMainnet } from '@lyra/web/constants/env'
import { Ticker } from '@lyra/web/constants/instruments'
import {
  DEFAULT_RFQ_SIZE,
  RfqBestQuote,
  SendRfqParams,
  SendRfqResponse,
} from '@lyra/web/constants/rfqs'
import { BASE_FEE } from '@lyra/web/constants/trade'
import { HELP_FEES_URL } from '@lyra/web/constants/urls'
import TradePointsRow from '@lyra/web/containers/trade/TradePointsRow'
import { getRfqBestBidAskOffer, getRfqGcdAmount, getRfqMarkValue } from '@lyra/web/utils/rfqs'
import { useMemo } from 'react'
import { XStack, YStack } from 'tamagui'

type Props = {
  rfq: SendRfqParams | SendRfqResponse
  tickers?: Record<string, Ticker>
  quote?: RfqBestQuote | null
  isLoading?: boolean
}

const OptionsRfqReceiptRows = ({ rfq, tickers, quote, isLoading }: Props) => {
  const markValue = tickers ? getRfqMarkValue(tickers, rfq.legs) : 0

  const amount = useMemo(() => {
    const amount = getRfqGcdAmount(rfq.legs)
    return amount ? amount : DEFAULT_RFQ_SIZE
  }, [rfq.legs])

  const fees = quote ? +quote.estimated_fee : 0
  const totalCost = quote ? +quote.estimated_total_cost : 0

  const totalCostPerSpread = amount !== 0 && totalCost ? totalCost / amount : 0

  const pnl = quote ? +quote.estimated_realized_pnl_excl_fees : 0

  const bestOffer = tickers ? getRfqBestBidAskOffer(tickers, rfq.legs, true) : 0
  const bestOfferPerSpread = amount !== 0 && bestOffer ? bestOffer / amount : undefined

  const markValuePerSpread = amount !== 0 ? markValue / amount : undefined

  const liquidationPrice = quote?.post_liquidation_price ? +quote.post_liquidation_price : 0

  const upLiquidationPrice = quote && quote?.up_liquidation_price ? +quote.up_liquidation_price : 0
  const downLiquidationPrice =
    quote && quote?.down_liquidation_price ? +quote.down_liquidation_price : 0

  // set mark value to $0 if sign is not equal to total cost
  const markValuePerSpreadAbs =
    totalCostPerSpread &&
    markValuePerSpread &&
    Math.sign(totalCostPerSpread) === Math.sign(markValuePerSpread)
      ? Math.abs(markValuePerSpread)
      : 0

  // set best offer to $0 if sign is not equal to total cost
  const bestOfferPerSpreadAbs =
    totalCostPerSpread &&
    bestOfferPerSpread &&
    Math.sign(totalCostPerSpread) === Math.sign(bestOfferPerSpread)
      ? Math.abs(bestOfferPerSpread)
      : 0

  return (
    <Section.YStack>
      {pnl ? (
        <XStack justifyContent="space-between">
          <LabelText>Profit / Loss</LabelText>
          <BodyText color={pnl > 0 ? 'green' : 'red'}>
            {formatUSD(pnl, { showSign: true })}
          </BodyText>
        </XStack>
      ) : null}
      {isMainnet ? <TradePointsRow fee={Math.max((fees ?? 0) - BASE_FEE, 0)} /> : null}
      <XStack justifyContent="space-between">
        <TooltipText
          label="Fees"
          tooltipContent={
            <BodyText color="secondary">
              <Link href={HELP_FEES_URL} label="View maker / taker fees" />
            </BodyText>
          }
          stayOpenOnHover
          placement="top-end"
        />
        <BodyText>{fees ? formatUSD(fees) : isLoading ? '...' : '-'}</BodyText>
      </XStack>
      {upLiquidationPrice && downLiquidationPrice ? (
        <>
          <XStack justifyContent="space-between">
            <LabelText>Up Liquidation Price</LabelText>
            <BodyText>
              {isLoading ? '...' : upLiquidationPrice ? formatUSD(upLiquidationPrice) : 'None'}
            </BodyText>
          </XStack>
          <XStack justifyContent="space-between">
            <LabelText>Down Liquidation Price</LabelText>
            <BodyText>
              {isLoading ? '...' : downLiquidationPrice ? formatUSD(downLiquidationPrice) : 'None'}
            </BodyText>
          </XStack>
        </>
      ) : (
        <XStack justifyContent="space-between">
          <LabelText>Liquidation Price</LabelText>
          <BodyText>
            {isLoading ? '...' : liquidationPrice ? formatUSD(liquidationPrice) : 'None'}
          </BodyText>
        </XStack>
      )}
      <XStack justifyContent="space-between">
        <TooltipText
          label="Price Per Option"
          tooltipContent="The quoted price per spread."
          placement="top-end"
        />
        <BodyText>
          {totalCostPerSpread ? formatUSD(Math.abs(totalCostPerSpread)) : isLoading ? '...' : '-'}
        </BodyText>
      </XStack>
      <XStack justifyContent="space-between">
        <TooltipText
          label="Mark Price"
          tooltipContent="The fair price per spread based on oracle prices."
          placement="top-end"
        />
        <BodyText>
          {isLoading
            ? '...'
            : markValuePerSpread !== undefined
            ? formatUSD(Math.abs(markValuePerSpreadAbs))
            : '-'}
        </BodyText>
      </XStack>
      <XStack justifyContent="space-between">
        <TooltipText
          label="BBO"
          tooltipContent="The price per spread based on the sum of the best bid / ask offers on the orderbook. If an option is missing a best bid / ask offer, this value is ignored."
          placement="top-end"
        />
        <BodyText>
          {isLoading
            ? '...'
            : bestOfferPerSpread !== undefined
            ? formatUSD(Math.abs(bestOfferPerSpreadAbs))
            : '-'}
        </BodyText>
      </XStack>
      {'rfq_id' in rfq ? (
        <XStack justifyContent="space-between">
          <TooltipText
            label="Developers"
            stayOpenOnHover
            tooltipContent={
              <YStack gap="$1">
                <XStack gap="$2" alignItems="center">
                  <BodyText color="secondary">RFQ ID</BodyText>
                  <BodyText marginLeft="auto">{rfq.rfq_id.substring(0, 10) + '...'}</BodyText>
                  <CopyButton value={rfq.rfq_id} size="sm" />
                </XStack>
                <XStack gap="$2" alignItems="center">
                  <BodyText color="secondary">Subaccount ID</BodyText>
                  <BodyText marginLeft="auto">{rfq.subaccount_id}</BodyText>
                  <CopyButton value={rfq.rfq_id} size="sm" />
                </XStack>
                <XStack gap="$2" justifyContent="space-between" alignItems="center">
                  <BodyText color="secondary">Max Total Cost</BodyText>
                  <BodyText>{rfq.max_total_cost ? formatUSD(+rfq.max_total_cost) : '-'}</BodyText>
                </XStack>
                <XStack gap="$2" justifyContent="space-between" alignItems="center">
                  <BodyText color="secondary">Counterparties</BodyText>
                  <BodyText>{JSON.stringify(rfq.counterparties)}</BodyText>
                </XStack>
                <XStack gap="$2" justifyContent="space-between" alignItems="center">
                  <BodyText color="secondary">Valid Until</BodyText>
                  <BodyText>
                    <Countdown endTimestamp={rfq.valid_until} />
                  </BodyText>
                </XStack>
              </YStack>
            }
            placement="top-end"
          />
        </XStack>
      ) : null}
    </Section.YStack>
  )
}

export default OptionsRfqReceiptRows
