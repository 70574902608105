import { RpcError } from '@lyra/core/api/error'
import Notice from '@lyra/core/components/Notice'
import React from 'react'

type Props = {
  error: Error
}

export default function TradeErrorNotice({ error }: Props) {
  return (
    <Notice
      message={
        error instanceof RpcError && error.data
          ? `${error.message}: ${
              typeof error.data === 'string' ? error.data : JSON.stringify(error.data)
            }`
          : error.message
      }
      status="error"
      width="100%"
    />
  )
}
