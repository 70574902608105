import BodyText from '@lyra/core/components/BodyText'
import Button from '@lyra/core/components/Button'
import { X } from '@lyra/core/components/Icon'
import IconSquare from '@lyra/core/components/IconSquare'
import NumberInput from '@lyra/core/components/Input/NumberInput'
import Section from '@lyra/core/components/Section'
import TooltipText from '@lyra/core/components/TooltipText'
import formatUSD from '@lyra/core/utils/formatUSD'
import {
  formatInstrument,
  formatOptionExpiry,
  parseOptionFromInstrumentName,
} from '@lyra/web/utils/instruments'
import React from 'react'
import { XStack, YStack } from 'tamagui'

import { SelectedOption } from '../../../constants/options'
import emptyFunction from '../../../utils/emptyFunction'
import MarketIcon from '../../common/MarketIcon'

type Props = {
  selectedOption: SelectedOption
  bestPrice: number
  isCustomRatio?: boolean
  isRatioFrozen?: boolean
  canDeselect?: boolean
  size?: number
  hasNoLiquidity?: boolean
  isRfq?: boolean
  onDeselect?: () => void
  onRatioChange?: (size: number) => void
} & (
  | {
      isRfq: true
      markPrice: number
    }
  | {
      isRfq?: false
      markPrice?: undefined
    }
)

const SelectedOptionRow = ({
  selectedOption,
  bestPrice,
  isRfq,
  markPrice,
  hasNoLiquidity = false,
  isCustomRatio = false,
  isRatioFrozen = false,
  canDeselect = false,
  size = 0,
  onDeselect = emptyFunction,
  onRatioChange = emptyFunction,
}: Props) => {
  const marketId = parseOptionFromInstrumentName(selectedOption.instrumentName)?.marketId
  return (
    <Section.XStack alignItems="center" justifyContent="space-between">
      <XStack alignItems="center" gap="$1">
        {isCustomRatio ? (
          isRatioFrozen ? (
            <IconSquare>
              <XStack justifyContent="center">
                <BodyText>{size}</BodyText>
              </XStack>
            </IconSquare>
          ) : (
            <NumberInput
              textAlign="center"
              alignItems="center"
              value={size}
              onChangeValue={onRatioChange}
              width={36}
            />
          )
        ) : marketId ? (
          <MarketIcon size={28} marketId={marketId} />
        ) : null}
        <YStack>
          <BodyText>{formatInstrument(selectedOption.instrumentName)}</BodyText>
          <XStack alignItems="center" gap="$0.5">
            <BodyText color={selectedOption.isBuy ? 'green' : 'red'}>
              {selectedOption.isBuy ? 'Long' : 'Short'}
            </BodyText>
            <BodyText color="secondary">
              {formatOptionExpiry(selectedOption.instrumentName)}
            </BodyText>
          </XStack>
        </YStack>
      </XStack>
      <XStack alignItems="center" gap="$1">
        {hasNoLiquidity ? (
          <TooltipText
            color="amber"
            tooltipContent="There is no liquidity for this option. Derive uses an estimate for best bid/ask based on the options mark price."
            label={formatUSD(selectedOption.isBuy ? bestPrice : -bestPrice)}
          />
        ) : isRfq && !hasNoLiquidity ? (
          <BodyText>{formatUSD(markPrice)}</BodyText>
        ) : (
          <BodyText>{formatUSD(selectedOption.isBuy ? bestPrice : -bestPrice)}</BodyText>
        )}
        {canDeselect ? (
          <Button icon={<X />} isTransparent iconSize={16} size="sm" onPress={onDeselect} />
        ) : null}
      </XStack>
    </Section.XStack>
  )
}
export default SelectedOptionRow
