import BodyText from '@lyra/core/components/BodyText'
import DataLabelRow from '@lyra/core/components/DataLabelRow'
import Section from '@lyra/core/components/Section'
import formatUSD from '@lyra/core/utils/formatUSD'
import PayoffChart from '@lyra/web/components/common/PayoffChart'
import { Ticker } from '@lyra/web/constants/instruments'
import {
  getOptionBreakEvenPrice,
  getOptionMaxLoss,
  getOptionMaxProfit,
  getOptionProjectedSettlePnl,
} from '@lyra/web/utils/order'
import { useState } from 'react'
import { StackProps, XStack } from 'tamagui'

type Props = {
  ticker: Ticker
  isBuy: boolean
  size: number
  limitPrice: number
  defaultLimitPrice: number
} & StackProps

export default function OptionsOrderbookPayoffRows({
  ticker,
  isBuy,
  size,
  limitPrice,
  defaultLimitPrice,
  ...stackProps
}: Props) {
  const [hoverPayoff, setHoverPayoff] = useState<number | null>(null)
  const isCall = ticker.option_details.option_type === 'C'
  const strikePrice = +ticker.option_details.strike
  const spotPrice = ticker.index_price !== null ? +ticker.index_price : 0

  const payoffAtSpotPrice = getOptionProjectedSettlePnl(
    isBuy,
    isCall,
    +ticker.option_details.strike,
    spotPrice,
    defaultLimitPrice,
    size,
    0
  )
  const payoffAdjustment = isBuy
    ? Math.abs(defaultLimitPrice) - Math.abs(limitPrice)
    : -(Math.abs(defaultLimitPrice) - Math.abs(limitPrice))

  const expectedPayoff = hoverPayoff ?? payoffAtSpotPrice + payoffAdjustment
  const projectedMaxProfit = getOptionMaxProfit(isBuy, size, ticker) + payoffAdjustment
  const projectedMaxLoss = getOptionMaxLoss(isBuy, size, ticker) + payoffAdjustment
  const breakEven =
    getOptionBreakEvenPrice(isCall, strikePrice, defaultLimitPrice) + payoffAdjustment

  return (
    <Section.YStack {...stackProps}>
      <XStack minHeight={100} flexGrow={1} flexShrink={1} overflow="visible">
        <PayoffChart
          optionsData={[
            {
              isCall: isCall,
              strikePrice: strikePrice,
              isBuy: isBuy,
              pricePerOption: defaultLimitPrice,
              size: size,
            },
          ]}
          expectedPayoff={expectedPayoff}
          currentSpotPrice={spotPrice}
          onHover={(pt) => setHoverPayoff(pt?.payoff ?? null)}
          marketName={ticker.base_currency.toUpperCase()}
          payoffAdjustment={payoffAdjustment}
        />
      </XStack>
      <DataLabelRow
        label="Expected Profit / Loss"
        value={
          <BodyText color={expectedPayoff >= 0 ? 'green' : 'red'}>
            {formatUSD(expectedPayoff, { showSign: true, showCommas: false, dps: 2 })}
          </BodyText>
        }
      />
      <DataLabelRow
        label="Max Profit"
        value={
          projectedMaxProfit === Number.MAX_VALUE
            ? 'Infinite'
            : formatUSD(projectedMaxProfit, {
                showSign: false,
                showCommas: false,
                dps: 2,
              })
        }
      />
      <DataLabelRow
        label="Breakeven"
        value={formatUSD(breakEven, { showSign: false, showCommas: false, dps: 2 })}
      />
      <DataLabelRow
        label="Max Loss"
        value={
          projectedMaxLoss === Number.MAX_VALUE
            ? 'Infinite'
            : formatUSD(projectedMaxLoss, { showSign: false, showCommas: false, dps: 2 })
        }
      />
    </Section.YStack>
  )
}
