import { RpcError } from '@lyra/core/api/error'
import BodyText from '@lyra/core/components/BodyText'
import Button from '@lyra/core/components/Button'
import NumberInput from '@lyra/core/components/Input/NumberInput'
import Notice from '@lyra/core/components/Notice'
import Section from '@lyra/core/components/Section'
import { MarketId } from '@lyra/web/constants/markets'
import { QueryParam, QueryPositionTab } from '@lyra/web/constants/query'
import { ExecuteRfqResponse, SendRfqResponse } from '@lyra/web/constants/rfqs'
import useOrderbookTimestamp from '@lyra/web/hooks/useOrderbookTimestamp'
import useRfqBestQuote from '@lyra/web/hooks/useRfqBestQuote'
import useSubaccount from '@lyra/web/hooks/useSubaccount'
import useTickers from '@lyra/web/hooks/useTickers'
import emptyFunction from '@lyra/web/utils/emptyFunction'
import { getDefaultLimitPrice } from '@lyra/web/utils/order'
import { getRfqGcdAmount } from '@lyra/web/utils/rfqs'
import { parseAsStringEnum, useQueryState } from 'next-usequerystate'
import { useCallback, useEffect, useMemo, useRef, useState } from 'react'

import TradeErrorNotice from '../../TradeErrorNotice'
import TradeExecuteButtonRow from '../../TradeExecuteButtonRow'
import OptionsRfqReceiptRows from './OptionsRfqReceiptRows'
import OptionsRfqTicketRows from './OptionsRfqTicketRows'
import SelectedOptionRow from './SelectedOptionRow'

type Props = {
  marketId: MarketId
  rfq: SendRfqResponse
  onCancelRfq: () => void
  onResendRfq: (rfq: SendRfqResponse) => void
  onExecuteRfq: (rfq: ExecuteRfqResponse) => void
}

const OptionsRfqExecuteSection = ({
  marketId,
  rfq,
  onCancelRfq,
  onResendRfq,
  onExecuteRfq,
}: Props) => {
  const [isResending, setIsResending] = useState(false)

  const { subaccount, executeRfq, sendRfq, cancelRfq } = useSubaccount()

  const [resendRfqError, setResendRfqError] = useState<Error>()
  const [executeRfqError, setExecuteRfqError] = useState<Error>()

  const { data: quote, isLoading: _isLoading, error: quoteError } = useRfqBestQuote(rfq)

  const error = resendRfqError ?? executeRfqError ?? quoteError

  const [_0, setPositionTab] = useQueryState(
    QueryParam.PositionTab,
    parseAsStringEnum(Object.values(QueryPositionTab))
  )

  const isLoading = _isLoading && !quote

  const bestQuote = quote?.best_quote
  const bestQuoteId = bestQuote?.quote_id

  const amount = useMemo(() => getRfqGcdAmount(rfq.legs), [rfq.legs])

  const legRatios = useMemo(() => {
    return rfq.legs.reduce(
      (dict, leg) => ({
        ...dict,
        [leg.instrument_name]: amount > 0 ? +leg.amount / amount : 0,
      }),
      {} as Record<string, number>
    )
  }, [rfq, amount])

  const isCustomRatio = useMemo(() => {
    const firstLeg = rfq.legs[0]
    if (!firstLeg) {
      return false
    }
    return !rfq.legs.every((leg) => leg.amount === firstLeg.amount)
  }, [rfq])

  const pnl = quote ? +quote.estimated_realized_pnl_excl_fees : 0

  const { getTimestamp } = useOrderbookTimestamp()

  const handleResendRfq = useCallback(async () => {
    setResendRfqError(undefined)
    setIsResending(true)
    try {
      if (!subaccount) {
        throw new Error('No subaccount')
      }

      try {
        await cancelRfq({ rfq_id: rfq.rfq_id, subaccount_id: subaccount.subaccount_id })
      } catch (error) {
        if (error instanceof RpcError && error.code === 11101) {
          // rfq already cancelled
        } else {
          // failed to cancel
          throw error
        }
      }

      const resendRfqResult = await sendRfq({
        legs: rfq.legs,
        max_total_cost: rfq.max_total_cost,
        min_total_cost: rfq.min_total_cost,
        subaccount_id: subaccount.subaccount_id,
      })

      console.debug({ resendRfqResult })

      onResendRfq(resendRfqResult)
    } catch (error) {
      if (error instanceof Error) {
        setResendRfqError(error)
      }
      console.error(error)
    } finally {
      setIsResending(false)
    }
  }, [subaccount, sendRfq, cancelRfq, rfq, onResendRfq])

  const timeToExpiry = Math.max(0, rfq.valid_until - getTimestamp())

  const totalCost = bestQuote ? +quote.estimated_total_cost : 0
  const [isPriceMove, setIsPriceMove] = useState(false)
  const prevTotalCost = useRef({ bestQuoteId, totalCost })
  useEffect(() => {
    if (
      prevTotalCost.current.totalCost &&
      totalCost &&
      // new cost higher than previous cost
      totalCost > prevTotalCost.current.totalCost
    ) {
      // price has moved unfavourably, block trade form for 3s
      setIsPriceMove(true)
      setTimeout(() => setIsPriceMove(false), 3 * 1000)
    }
    prevTotalCost.current = {
      bestQuoteId,
      totalCost,
    }
  }, [totalCost, bestQuoteId])

  const tickers = useTickers(rfq.legs.map((leg) => leg.instrument_name))

  const handleExecuteRfq = useCallback(async () => {
    setExecuteRfqError(undefined)

    try {
      if (!subaccount) {
        throw new Error('No subaccount')
      }

      if (!bestQuote || !quote) {
        throw new Error('No quote available')
      }

      if (!tickers) {
        throw new Error('Tickers are not loaded')
      }

      const baseAssetSubIds = Object.values(tickers).reduce(
        (map, ticker) => ({
          ...map,
          [ticker.instrument_name]: ticker.base_asset_sub_id,
        }),
        {}
      )

      const res = await executeRfq(
        baseAssetSubIds,
        {
          // always buying, the direction of the individual legs is what matters
          direction: 'buy',
          legs: bestQuote.legs,
          rfq_id: bestQuote.rfq_id,
          subaccount_id: subaccount.subaccount_id,
          max_fee: (+quote.suggested_max_fee).toFixed(2),
          quote_id: bestQuote.quote_id,
        },
        marketId
      )

      if (res.status === 'filled') {
        // open positions tab on instantly filled order
        setPositionTab(QueryPositionTab.Positions)
      }

      onExecuteRfq(res)
    } catch (error) {
      if (error instanceof Error) {
        setExecuteRfqError(error)
      }
      console.error(error)
    }
  }, [subaccount, bestQuote, quote, tickers, executeRfq, marketId, setPositionTab, onExecuteRfq])

  return (
    <>
      {rfq.legs.map((leg) => (
        <SelectedOptionRow
          key={leg.instrument_name}
          instrumentName={leg.instrument_name}
          isBuy={leg.direction === 'buy'}
          ratio={legRatios[leg.instrument_name]}
          price={
            tickers
              ? getDefaultLimitPrice(tickers[leg.instrument_name], leg.direction === 'buy') ?? 0
              : 0
          }
          showPriceSign
          showRatio={isCustomRatio}
        />
      ))}
      <Section.XStack justifyContent="space-between" alignItems="center">
        <BodyText>Size</BodyText>
        <NumberInput onChangeValue={emptyFunction} value={amount} isDisabled width="50%" />
      </Section.XStack>
      <Section.Separator />
      <OptionsRfqTicketRows quote={bestQuote ? quote : undefined} isLoading={isLoading} />
      <TradeExecuteButtonRow
        isInsufficientBalance={!!bestQuote && !!quote && !quote.is_valid}
        insufficientBalanceMessage={bestQuote ? quote?.invalid_reason : undefined}
      >
        {timeToExpiry === 0 ? (
          <Notice message="Your request for quote expired. Re-request the same quote or go back to modify your quote." />
        ) : error ? (
          <TradeErrorNotice error={error} />
        ) : null}
        {timeToExpiry === 0 ? (
          <Button label="Re-request Quote" isCta size="lg" width="100%" onPress={handleResendRfq} />
        ) : (
          <Button
            label={
              isPriceMove
                ? 'Quote Updated'
                : isResending
                ? 'Requesting Quote'
                : !bestQuote
                ? 'Waiting For Quote'
                : // if pnl is non-zero, user is closing an open position
                pnl !== 0
                ? 'Close Position'
                : 'Open Position'
            }
            isCta
            size="lg"
            onPress={handleExecuteRfq}
            isDisabled={isPriceMove || isResending || !bestQuote || !subaccount}
            width="100%"
            isLoading={isPriceMove || isResending || !bestQuote}
          />
        )}
        <Button width="100%" label="Go Back" size="lg" onPress={onCancelRfq} />
      </TradeExecuteButtonRow>
      <Section.Separator />
      <OptionsRfqReceiptRows rfq={rfq} tickers={tickers} quote={quote} isLoading={isLoading} />
    </>
  )
}

export default OptionsRfqExecuteSection
