import Button from '@lyra/core/components/Button'
import { X } from '@lyra/core/components/Icon'
import Section from '@lyra/core/components/Section'
import TabsHorizontal from '@lyra/web/components/layout/TabsHorizontal'
import { Ticker } from '@lyra/web/constants/instruments'
import { QuerySpotOrderType } from '@lyra/web/constants/query'
import { LegsUnpriced } from '@lyra/web/constants/rfqs'
import useStrategy from '@lyra/web/hooks/useStrategy'
import { formatStrategyName } from '@lyra/web/utils/strategies'
import React from 'react'

type Props = {
  children: React.ReactNode
  tickers: Record<string, Ticker>
  legs: LegsUnpriced
  orderType: QuerySpotOrderType
  onChangeOrderType: (orderType: QuerySpotOrderType) => void
  onClose: () => void
}

const formatOrderType = (orderType: QuerySpotOrderType): string => {
  switch (orderType) {
    case QuerySpotOrderType.Limit:
      return 'Limit'
    case QuerySpotOrderType.Rfq:
      return 'RFQ'
  }
}

export default function OptionsTradeFormContainer({
  children,
  tickers,
  legs,
  orderType,
  onChangeOrderType,
  onClose,
}: Props) {
  const strategy = useStrategy(tickers, legs)

  return (
    <Section>
      <Section.Subheader
        title={formatStrategyName(strategy)}
        rightContent={<Button icon={<X />} size="sm" isTransparent onPress={onClose} />}
      />
      <Section.XStack isCompact>
        <TabsHorizontal
          pages={Object.values(QuerySpotOrderType).map((thisOrderType) => {
            return {
              name: formatOrderType(thisOrderType),
              isSelected: thisOrderType === orderType,
              onPress: () => onChangeOrderType(thisOrderType),
            }
          })}
          width="100%"
          borderBottomColor="$hairline"
          borderBottomWidth={1}
          size="sm"
          isBodyText
          tabProps={{
            flexGrow: 1,
            flexShrink: 1,
            flexBasis: 0,
            maxWidth: 105,
          }}
        />
      </Section.XStack>
      {children}
    </Section>
  )
}
