import React, { useEffect, useState } from 'react'

import SpanText, { SpanTextProps } from '../SpanText'

function calculateTimeLeft(
  endTimestamp: number,
  options: { showDays?: boolean; hideSeconds?: boolean } = {}
) {
  const { showDays = false, hideSeconds = false } = options
  const currentTimestamp = Math.floor(Date.now())
  const timeDiff = endTimestamp - currentTimestamp

  if (timeDiff <= 0) {
    return { timeDiff, display: '0s' }
  }

  // Calculate full days difference based on the timestamp, ensuring it's always rounded down
  const daysDiff = Math.floor((endTimestamp - currentTimestamp) / (1000 * 60 * 60 * 24))

  // Calculate hours, minutes, and seconds
  let hours = Math.floor((timeDiff / (1000 * 60 * 60)) % 24)
  const minutes = Math.floor((timeDiff / (1000 * 60)) % 60)
  const seconds = Math.floor((timeDiff / 1000) % 60)

  // Construct display string
  let display = ''
  if (showDays && daysDiff !== 0) {
    display += `${daysDiff}d `
  } else {
    hours += daysDiff * 24
  }
  display += `${hours}h ${minutes}m`

  if (!hideSeconds) {
    display += ` ${seconds}s`
  }

  return {
    timeDiff,
    display,
  }
}

type Props = {
  endTimestamp: number
  showDays?: boolean
  hideSeconds?: boolean
} & SpanTextProps

const Countdown = ({ endTimestamp, showDays, hideSeconds, ...textProps }: Props) => {
  const [timeLeft, setTimeLeft] = useState(
    calculateTimeLeft(endTimestamp, { showDays, hideSeconds })
  )

  useEffect(() => {
    const timer = setInterval(() => {
      setTimeLeft(calculateTimeLeft(endTimestamp, { showDays, hideSeconds }))
    }, 1000)

    return () => clearInterval(timer)
  }, [endTimestamp, showDays])

  return <SpanText {...textProps}>{timeLeft.display}</SpanText>
}

export default Countdown
