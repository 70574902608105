import BodyText from '@lyra/core/components/BodyText'
import Button from '@lyra/core/components/Button'
import { Plus, X } from '@lyra/core/components/Icon'
import IconSquare from '@lyra/core/components/IconSquare'
import Section from '@lyra/core/components/Section'
import { QuerySpotOrderType } from '@lyra/web/constants/query'
import useOptionsOrderTypeQueryParam from '@lyra/web/hooks/useOptionsOrderTypeQueryParam'
import { XStack, YStack } from 'tamagui'

const OptionsTradeFormMultiSelectRow = () => {
  const [orderType, setOrderType] = useOptionsOrderTypeQueryParam()

  return orderType === QuerySpotOrderType.Rfq ? (
    <Section.XStack>
      <XStack gap="$1.5" alignItems="center">
        <IconSquare>
          <Plus />
        </IconSquare>
        <YStack>
          <BodyText>Select Option</BodyText>
          <BodyText color="secondary">Select from board</BodyText>
        </YStack>
      </XStack>
      <Button
        marginLeft="auto"
        icon={<X />}
        isTransparent
        iconSize={16}
        size="sm"
        onPress={() => setOrderType(QuerySpotOrderType.Limit)}
      />
    </Section.XStack>
  ) : (
    <Section.XStack>
      <Button
        size="sm"
        label="Add Option"
        leftIcon={<Plus />}
        onPress={() => setOrderType(QuerySpotOrderType.Rfq)}
      />
    </Section.XStack>
  )
}

export default OptionsTradeFormMultiSelectRow
