import { getTextColorVar } from '@lyra/core/utils/text'
import React from 'react'
import { styled, XStack } from 'tamagui'

import Icon, { IconColor } from '../Icon'

export type IconSquareColor = Omit<IconColor, 'inherit'>

export type IconSquareProps = {
  color?: IconSquareColor
  children: React.ReactElement
}

const getIconSquareBgColor = (color: IconSquareColor) => {
  switch (color) {
    case 'primary':
    case 'secondary':
      return 'transparent'
    case 'amber':
      return '$amberBg'
    case 'green':
      return '$greenBg'
    case 'red':
      return '$redBg'
    case 'inverted':
      return '$invertedBg'
  }
}

const IconSquareStack = styled(XStack, {
  width: '$6',
  height: '$6',
  alignItems: 'center',
  justifyContent: 'center',
  borderWidth: 1,
  borderRadius: '$1',
})

const IconSquare = ({ color = 'primary', children }: IconSquareProps) => {
  return (
    <IconSquareStack
      borderColor={color === 'primary' ? '$hairline' : getTextColorVar(color as IconColor)}
      backgroundColor={getIconSquareBgColor(color)}
    >
      <Icon icon={children} size={16} color={color === 'inverted' ? 'inverted' : 'primary'} />
    </IconSquareStack>
  )
}

IconSquare.displayName = 'IconSquare'

export default IconSquare
