import BodyText from '@lyra/core/components/BodyText'
import LabelText from '@lyra/core/components/LabelText'
import TextAmountUpdate from '@lyra/core/components/TextAmountUpdate'
import formatUSD from '@lyra/core/utils/formatUSD'
import { Quote } from '@lyra/web/constants/order'
import useSubaccount from '@lyra/web/hooks/useSubaccount'
import { XStack } from 'tamagui'

type Props = {
  quote?: Quote
}

export default function TradeBuyingPowerRow({ quote }: Props) {
  const { buyingPower } = useSubaccount()
  // Fee is accounted for in quote
  const marginRequired = quote
    ? Math.max(0, +quote.pre_initial_margin - +quote.post_initial_margin)
    : 0

  return (
    <XStack alignItems="center" justifyContent="space-between">
      <LabelText>Buying Power</LabelText>
      {quote ? (
        <TextAmountUpdate
          prevAmount={buyingPower}
          newAmount={Math.max(0, buyingPower - marginRequired)}
          // Note: quote.is_valid should always be false when user has negative buying power
          newAmountColor={quote && !quote.is_valid ? 'red' : undefined}
          format={formatUSD}
        />
      ) : (
        <BodyText>{formatUSD(buyingPower)}</BodyText>
      )}
    </XStack>
  )
}
