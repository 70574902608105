import { LegUnpricedSchema } from '@lyra/core/api/types/private.send_rfq'
import filterNulls from '@lyra/core/utils/filterNulls'
import { useMemo } from 'react'

import { Ticker } from '../constants/instruments'
import { getStrategyKey, LegData, OptionsStrategy } from '../constants/strategies'
import { parseOptionFromInstrumentName } from '../utils/instruments'
import { getRfqMarkValue } from '../utils/rfqs'
import { getStrategy } from '../utils/strategies'

const useStrategy = (tickers?: Record<string, Ticker>, legs?: LegUnpricedSchema[]) => {
  // order by strike price, and then calls -> puts, similar
  const sortedLegData: LegData[] = useMemo(
    () =>
      legs
        ? filterNulls(
            legs.map((leg) => {
              const parsed = parseOptionFromInstrumentName(leg.instrument_name)
              return parsed
                ? {
                    leg: leg,
                    parsed: parsed,
                  }
                : null
            })
          ).sort((a, b) =>
            a.parsed.strikePrice !== b.parsed.strikePrice
              ? +a.parsed.strikePrice - +b.parsed.strikePrice
              : b.parsed.isCall
              ? 1
              : -1
          )
        : [],
    [legs]
  )

  const markValue = tickers && legs ? getRfqMarkValue(tickers, legs) : undefined
  const isCredit = !!markValue && markValue < 0

  // build a "Strategy Key" which is C/D (credit/debit): BC/BP/SC/SP.. (buy/sell call/put)
  const strategy = useMemo(() => {
    const strategyKey = getStrategyKey(sortedLegData, isCredit)
    return getStrategy(sortedLegData, strategyKey) ?? OptionsStrategy.Custom
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sortedLegData, isCredit])

  return strategy
}

export default useStrategy
