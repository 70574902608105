import BodyText from '@lyra/core/components/BodyText'
import Button from '@lyra/core/components/Button'
import DataLabelRow from '@lyra/core/components/DataLabelRow'
import { Settings2 } from '@lyra/core/components/Icon'
import NumberInput from '@lyra/core/components/Input/NumberInput'
import Section from '@lyra/core/components/Section'
import TextAmountUpdate from '@lyra/core/components/TextAmountUpdate'
import { SECONDS_IN_DAY } from '@lyra/core/constants/time'
import formatNumber from '@lyra/core/utils/formatNumber'
import formatUSD from '@lyra/core/utils/formatUSD'
import { isMainnet } from '@lyra/web/constants/env'
import { InstrumentType, Ticker } from '@lyra/web/constants/instruments'
import { MarketId } from '@lyra/web/constants/markets'
import {
  DEFAULT_ORDER_EXPIRY_DAYS,
  OrderParamsWithoutSignature,
  OrderType,
  TimeInForce,
} from '@lyra/web/constants/order'
import { Position } from '@lyra/web/constants/position'
import { BASE_FEE } from '@lyra/web/constants/trade'
import useQuoteOrder from '@lyra/web/hooks/useQuoteOrder'
import useSubaccount from '@lyra/web/hooks/useSubaccount'
import useTradeLimitPriceInput from '@lyra/web/hooks/useTradeLimitPriceInput'
import useTradeSizeInput from '@lyra/web/hooks/useTradeSizeInput'
import {
  getBestAskPrice,
  getBestBidPrice,
  getBestOrMinOrMaxPrice,
  getBestPrice,
} from '@lyra/web/utils/instruments'
import { getEstTradeFee } from '@lyra/web/utils/order'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { YStack } from 'tamagui'

import SelectedOptionRow from '../../../../components/trade/TradeSections/SelectedOptionRow'
import { SelectedOption } from '../../../../constants/options'
import { countDecimals } from '../../../../utils/number'
import { BidOrAsk } from '../../../common/Orderbook/OrderbookTable'
import OrderbookExpiryRow, {
  TIME_IN_FORCE_CUSTOM_EXPIRY,
  TIME_IN_FORCE_LABELS,
} from '../../OrderbookExpiryRow'
import OrderbookReviewButtonRow from '../../OrderbookReviewButtonRow'
import OrderbookSubmitButtonRow from '../../OrderbookSubmitButtonRow'
import TradeBuyingPowerRow from '../../TradeBuyingPowerRow'
import TradePointsRow from '../../TradePointsRow'
import OptionsTradeFormMultiSelectRow from '../OptionsTradeFormMultiselectRow'
import OptionsOrderbookFooter from './OptionsOrderbookFooter'

type Props = {
  selectedOption: SelectedOption
  marketId: MarketId
  ticker: Ticker
  onDeselect: (option: SelectedOption) => void
  onSubmitOrder: () => void
}

const DEFAULT_QUOTE_SIZE = 1

const OptionsOrderbookTradeForm = ({
  selectedOption,
  ticker,
  marketId,
  onSubmitOrder,
  onDeselect,
}: Props) => {
  const [executeOrderParams, setExecuteOrderParams] = useState<OrderParamsWithoutSignature>()

  const { subaccount } = useSubaccount()
  const position: Position | undefined = useMemo(() => {
    if (subaccount) {
      const position = subaccount.positions.find(
        (position) => position.instrument_name === ticker.instrument_name
      )
      if (position && +position.amount !== 0) {
        return position
      }
    }
  }, [subaccount, ticker])

  const [sizeInput, setSizeInput] = useTradeSizeInput(+ticker.amount_step)
  const [limitPriceInput, setLimitPriceInput] = useTradeLimitPriceInput(+ticker.tick_size)
  const [timeInForce, setTimeInForce] = useState<TimeInForce>(TimeInForce.GoodTilCancelled)
  const [expiryDays, setExpiryDays] = useState(DEFAULT_ORDER_EXPIRY_DAYS)

  const reset = useCallback(() => {
    if (sizeInput === 0) {
      setSizeInput(DEFAULT_QUOTE_SIZE)
    }
    setLimitPriceInput(0)
    setExecuteOrderParams(undefined)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setLimitPriceInput, setSizeInput])

  useEffect(() => {
    reset()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ticker.instrument_name])

  const handlePressOrder = useCallback(
    ({ price }: BidOrAsk) => {
      setLimitPriceInput(price)
    },
    [setLimitPriceInput]
  )

  const size = executeOrderParams ? +executeOrderParams.amount : sizeInput

  const isBuy = selectedOption.isBuy

  const defaultLimitPrice = getBestOrMinOrMaxPrice(ticker, isBuy)
  const limitPrice = executeOrderParams
    ? +executeOrderParams.limit_price
    : limitPriceInput
    ? limitPriceInput
    : defaultLimitPrice

  const bestBidPrice = getBestBidPrice(ticker)
  const bestAskPrice = getBestAskPrice(ticker)
  const bestPrice = getBestPrice(ticker, isBuy)

  const isLong = position ? +position.amount > 0 : isBuy
  const isOpen = (isBuy && isLong) || (!isLong && !isBuy)
  const positionSize = position ? +position.amount : 0
  const positionSizeAbs = Math.abs(positionSize)

  const expirySecs = expiryDays * SECONDS_IN_DAY

  const quoteState = useQuoteOrder({
    marketId,
    instrumentName: ticker.instrument_name,
    orderType: OrderType.Limit,
    isBuy: isBuy,
    size,
    limitPrice,
    timeInForce,
    expirySecs,
  })
  const { quote, orderParams, error, isLoading } = quoteState

  // Fee is accounted for in quote
  const marginRequired = quote ? +quote.pre_initial_margin - +quote.post_initial_margin : 0

  const liquidationPrice = quote?.post_liquidation_price ? +quote.post_liquidation_price : 0

  const postTradePositionSize = (isBuy ? size : -size) + positionSize

  const handleCloseForm = useCallback(() => {
    onDeselect(selectedOption)
    reset()
  }, [onDeselect, reset, selectedOption])

  const fee = quote ? +quote.estimated_fee : getEstTradeFee(ticker, size)

  const minReceivedOrMaxCost = executeOrderParams
    ? +executeOrderParams.limit_price * +executeOrderParams.amount
    : limitPrice * size

  const sectionHeaderTitle = (isBuy ? 'Long ' : 'Short ').concat(
    selectedOption.instrumentName.includes('-C') ? 'Call' : 'Put'
  )

  const handlePressReviewOrder = useCallback(() => {
    if (!quote?.is_valid || !orderParams) {
      return
    }
    setExecuteOrderParams(orderParams)
  }, [orderParams, quote])

  const [isExpiryOpen, setIsExpiryOpen] = useState(false)

  if (ticker.instrument_type !== InstrumentType.Options) {
    return null
  }

  return (
    <>
      <SelectedOptionRow
        selectedOption={selectedOption}
        bestPrice={executeOrderParams ? +executeOrderParams.limit_price : defaultLimitPrice}
        canDeselect={!executeOrderParams}
        onDeselect={() => onDeselect(selectedOption)}
      />
      {!executeOrderParams ? <OptionsTradeFormMultiSelectRow /> : null}
      <Section.Separator />
      <Section.XStack alignItems="center" justifyContent="space-between">
        <YStack>
          <BodyText>Size</BodyText>
          <BodyText color="secondary">Contracts</BodyText>
        </YStack>
        {executeOrderParams ? (
          <BodyText>
            {formatNumber(+executeOrderParams.amount, {
              dps: countDecimals(+ticker.amount_step),
            })}
          </BodyText>
        ) : (
          <NumberInput
            width="40%"
            value={size}
            onChangeValue={setSizeInput}
            defaultValue={DEFAULT_QUOTE_SIZE}
            formatValue={(val) =>
              formatNumber(val, {
                dps: countDecimals(+ticker.amount_step),
                showCommas: false,
              })
            }
            rightContent={
              position && !isOpen ? (
                <Button
                  size="sm"
                  isSelected={positionSizeAbs === size}
                  label="Max"
                  onPress={() => setSizeInput(positionSizeAbs)}
                />
              ) : null
            }
          />
        )}
      </Section.XStack>
      <Section.XStack alignItems="center" justifyContent="space-between">
        <YStack>
          <BodyText>Limit Price</BodyText>
          <BodyText color="secondary">
            {bestBidPrice
              ? `Bid ${formatUSD(bestBidPrice, {
                  showCommas: false,
                  dps: countDecimals(+ticker.tick_size),
                })}`
              : 'No Bid'}{' '}
            ·{' '}
            {bestAskPrice
              ? `Ask ${formatUSD(bestAskPrice, {
                  showCommas: false,
                  dps: countDecimals(+ticker.tick_size),
                })}`
              : 'No Ask'}
          </BodyText>
        </YStack>
        {executeOrderParams ? (
          <BodyText>{formatUSD(limitPrice)}</BodyText>
        ) : (
          <NumberInput
            width="40%"
            value={limitPrice}
            defaultValue={defaultLimitPrice}
            formatValue={(val) =>
              formatUSD(val, { dps: countDecimals(+ticker.tick_size), showCommas: false })
            }
            status={!limitPriceInput && !bestPrice ? 'warning' : undefined}
            onChangeValue={setLimitPriceInput}
          />
        )}
      </Section.XStack>
      {!executeOrderParams ? (
        isExpiryOpen ? (
          <OrderbookExpiryRow
            timeInForce={timeInForce}
            onChangeTimeInForce={setTimeInForce}
            expiryDays={expiryDays}
            onChangeExpiryDays={setExpiryDays}
            onClose={() => setIsExpiryOpen(false)}
          />
        ) : (
          <Section.XStack>
            <Button
              leftIcon={<Settings2 />}
              isDisabled={!!executeOrderParams}
              label={TIME_IN_FORCE_LABELS[timeInForce]}
              size="sm"
              onPress={() => setIsExpiryOpen(true)}
            />
          </Section.XStack>
        )
      ) : (
        <Section.XStack alignItems="center" justifyContent="space-between">
          <YStack>
            <BodyText>Time In Force</BodyText>
            {TIME_IN_FORCE_CUSTOM_EXPIRY.includes(timeInForce) ? (
              <BodyText color="secondary">
                {expiryDays} Day{expiryDays === 1 ? '' : 's'}
              </BodyText>
            ) : null}
          </YStack>
          <BodyText>{TIME_IN_FORCE_LABELS[timeInForce]}</BodyText>
        </Section.XStack>
      )}
      <Section.Separator />
      <Section.YStack>
        {/* 
          order:
          - cost
          - margin required
          - fee
          - liquidation price
          - position
          - profit / loss
          - buying power
          - points
           */}
        <DataLabelRow
          label={!isBuy ? 'Min Received' : 'Max Cost'}
          // for orderbook, cost is always defined - so don't show ... even if isLoading
          value={isLoading && !minReceivedOrMaxCost ? '...' : formatUSD(minReceivedOrMaxCost)}
        />
        {isOpen || subaccount?.margin_type === 'PM' || marginRequired > 0 ? (
          <DataLabelRow
            label="Margin Required"
            value={isLoading ? '...' : formatUSD(marginRequired)}
          />
        ) : null}
        <DataLabelRow label="Fees" value={isLoading ? '...' : formatUSD(fee)} />
        {isOpen || subaccount?.margin_type === 'PM' || liquidationPrice ? (
          <DataLabelRow
            label="Liquidation Price"
            value={isLoading ? '...' : liquidationPrice ? formatUSD(liquidationPrice) : 'None'}
          />
        ) : null}
        {position ? (
          <DataLabelRow
            label="Position"
            value={
              <TextAmountUpdate
                prevAmountPrefix={positionSize > 0 ? 'Long' : 'Short'}
                prevAmount={positionSize}
                newAmountPrefix={postTradePositionSize > 0 ? 'Long' : 'Short'}
                newAmount={postTradePositionSize}
                newAmountColor={postTradePositionSize > 0 ? 'green' : 'red'}
                prevAmountColor="primary"
                format={(val) =>
                  `${formatNumber(Math.abs(val), {
                    minDps: 1,
                  })} ${ticker.option_details.option_type === 'C' ? 'Call' : 'Put'}${
                    Math.abs(val) !== 1 ? 's' : ''
                  }`
                }
              />
            }
          />
        ) : null}
        {position && !isOpen ? (
          <DataLabelRow
            label="Profit / Loss"
            value={
              isLoading ? (
                '...'
              ) : quote ? (
                <BodyText color={+quote.estimated_realized_pnl >= 0 ? 'green' : 'red'}>
                  {formatUSD(+quote.estimated_realized_pnl, { showSign: true })}
                </BodyText>
              ) : (
                '-'
              )
            }
          />
        ) : null}
        <TradeBuyingPowerRow quote={quote} />
        {isMainnet ? <TradePointsRow fee={Math.max((fee ?? 0) - BASE_FEE, 0)} /> : null}
      </Section.YStack>
      {executeOrderParams ? (
        <OrderbookSubmitButtonRow
          ticker={ticker}
          orderParams={executeOrderParams}
          quote={quote}
          error={error}
          onSubmit={onSubmitOrder}
          onCancel={() => setExecuteOrderParams(undefined)}
          color={isBuy ? 'green' : 'red'}
        />
      ) : (
        <OrderbookReviewButtonRow
          ticker={ticker}
          limitPrice={limitPrice}
          size={size}
          quote={quote}
          orderParams={orderParams}
          color={isBuy ? 'green' : 'red'}
          isBuy={isBuy}
          error={error}
          onPressReview={handlePressReviewOrder}
        />
      )}
      <Section.Separator />
      <OptionsOrderbookFooter
        limitPrice={limitPrice}
        defaultLimitPrice={defaultLimitPrice}
        size={size}
        isBuy={isBuy}
        ticker={ticker}
        marketId={marketId}
        onPressOrder={executeOrderParams ? undefined : handlePressOrder}
      />
    </>
  )
}

export default OptionsOrderbookTradeForm
