import BodyText from '@lyra/core/components/BodyText'
import LabelText from '@lyra/core/components/LabelText'
import Section from '@lyra/core/components/Section'
import formatUSD from '@lyra/core/utils/formatUSD'
import { RfqBestQuote } from '@lyra/web/constants/rfqs'
import useSubaccount from '@lyra/web/hooks/useSubaccount'
import { XStack } from 'tamagui'

type Props = {
  quote?: RfqBestQuote | null
  isEstimate?: boolean
  isLoading?: boolean
}

const OptionsRfqTicketRows = ({ quote, isEstimate, isLoading }: Props) => {
  const { buyingPower } = useSubaccount()

  const marginRequired = quote ? +quote.pre_initial_margin - +quote.post_initial_margin : 0
  const fees = quote && quote.estimated_fee ? +quote.estimated_fee : 0
  const totalCost = quote ? +quote.estimated_total_cost : 0
  const totalCostWithFees = totalCost + fees

  // max cost is always defined, if positive user is paying, if negative user is receiving
  const isBuy = totalCostWithFees >= 0

  const totalCostLabel =
    (isEstimate ? 'Est ' : '') +
    // use isBuyEstimate to indicate if user is paying or receiving
    (isBuy || (totalCostWithFees && totalCostWithFees > 0) ? 'Total Cost' : 'Total Received')

  return (
    <Section.YStack height={90}>
      <XStack justifyContent="space-between">
        <LabelText>{isLoading ? '...' : totalCostLabel}</LabelText>
        <BodyText>
          {
            // for orderbook, cost is always defined - so don't show ... even if isLoading
            isLoading ? '...' : totalCostWithFees ? formatUSD(Math.abs(totalCostWithFees)) : '-'
          }
        </BodyText>
      </XStack>
      <XStack justifyContent="space-between">
        <LabelText>
          {marginRequired && marginRequired < 0 ? 'Margin Unlocked' : 'Margin Required'}
        </LabelText>
        <BodyText>
          {isLoading ? '...' : marginRequired ? formatUSD(Math.abs(marginRequired)) : '-'}
        </BodyText>
      </XStack>
      <XStack justifyContent="space-between" width="100%">
        <LabelText>Buying Power</LabelText>
        <BodyText color={marginRequired && marginRequired > buyingPower ? 'red' : 'primary'}>
          {formatUSD(buyingPower)}
        </BodyText>
      </XStack>
    </Section.YStack>
  )
}

export default OptionsRfqTicketRows
