import { MarketId } from '@lyra/web/constants/markets'
import { DEFAULT_RFQ_SIZE, ExecuteRfqResponse, SendRfqResponse } from '@lyra/web/constants/rfqs'
import useSelectedOptions from '@lyra/web/hooks/useSelectedOptions'
import useSendRfqQueryParams from '@lyra/web/hooks/useSendRfqQueryParams'
import { getRfqGcdAmount } from '@lyra/web/utils/rfqs'
import { useCallback, useEffect, useState } from 'react'

import useSubaccount from '../../../../hooks/useSubaccount'
import OptionsRfqExecuteSection from './OptionsRfqExecuteSection'
import OptionsRfqSendSection from './OptionsRfqSendSection'

type Props = {
  marketId: MarketId
}

const OptionsRfqTradeForm = ({ marketId }: Props) => {
  const { cancelRfq, subaccount } = useSubaccount()

  const [sendRfqParams, setSendRfqParams] = useSendRfqQueryParams()
  const [sendRfqResponse, setSendRfqResponse] = useState<SendRfqResponse>()

  const { deselectOption } = useSelectedOptions()

  const handleCancelRfq = useCallback(async () => {
    if (!subaccount?.subaccount_id) {
      throw new Error('No subaccount')
    }

    if (!sendRfqResponse) {
      throw new Error('No open RFQ')
    }

    // immediately unset rfq response
    setSendRfqResponse(undefined)

    // attempt to cancel rfq
    await cancelRfq({ rfq_id: sendRfqResponse.rfq_id, subaccount_id: subaccount.subaccount_id })
  }, [cancelRfq, sendRfqResponse, subaccount])

  const handleExecuteRfq = useCallback(
    (executeRfqResponse: ExecuteRfqResponse) => {
      setSendRfqResponse(undefined)

      const _amount = getRfqGcdAmount(sendRfqParams.legs)
      const amount = _amount ? _amount : DEFAULT_RFQ_SIZE

      // after execution, preserve legs and ratios
      setSendRfqParams({
        legs: executeRfqResponse.legs.map((leg) => ({
          instrument_name: leg.instrument_name,
          direction: leg.direction,
          // divide by gcd to get ratio
          amount: (amount > 0 ? +leg.amount / amount : DEFAULT_RFQ_SIZE).toString(),
        })),
      })
    },
    [setSendRfqParams]
  )

  useEffect(() => {
    // on unmount, cancel rfq
    return () => {
      if (sendRfqResponse) {
        handleCancelRfq()
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    const sendLegs = sendRfqParams.legs
    const quoteLegs = sendRfqResponse?.legs
    if (
      sendLegs.length &&
      quoteLegs?.length &&
      // leg length is equal and every leg matches
      (sendLegs.length !== quoteLegs.length ||
        !sendLegs.every(
          (sendLeg, idx) =>
            sendLeg.amount === quoteLegs[idx].amount &&
            sendLeg.direction === quoteLegs[idx].direction &&
            sendLeg.instrument_name === quoteLegs[idx].instrument_name
        ))
    ) {
      // legs do not match, cancel rfq and go back to send rfq step
      setSendRfqResponse(undefined)
      handleCancelRfq()
    }
  }, [sendRfqResponse, sendRfqParams, handleCancelRfq])

  if (sendRfqResponse) {
    return (
      <OptionsRfqExecuteSection
        marketId={marketId}
        rfq={sendRfqResponse}
        onResendRfq={setSendRfqResponse}
        onCancelRfq={handleCancelRfq}
        onExecuteRfq={handleExecuteRfq}
      />
    )
  }

  return (
    <OptionsRfqSendSection
      sendRfqParams={sendRfqParams}
      onSendRfq={setSendRfqResponse}
      onChangeRfqParams={setSendRfqParams}
      onDeselectOption={deselectOption}
    />
  )
}

export default OptionsRfqTradeForm
