import Button from '@lyra/core/components/Button'
import ButtonGroup from '@lyra/core/components/ButtonGroup'
import Section from '@lyra/core/components/Section'
import { Ticker } from '@lyra/web/constants/instruments'
import { MarketId } from '@lyra/web/constants/markets'
import { Position } from '@lyra/web/constants/position'
import useSettings from '@lyra/web/hooks/useSettings'
import useSubaccount from '@lyra/web/hooks/useSubaccount'
import { TradeFormView } from '@lyra/web/utils/settings'
import { useMemo } from 'react'

import { getDefaultGroup } from '../../../../utils/orderbook'
import Orderbook from '../../../common/Orderbook'
import { BidOrAsk } from '../../../common/Orderbook/OrderbookTable'
import TradeFeed from '../../../common/TradeFeed'
import OptionsOrderbookPayoffRows from './OptionsOrderbookPayoffRows'

type Props = {
  limitPrice: number
  defaultLimitPrice: number
  size: number
  isBuy: boolean
  marketId: MarketId
  ticker: Ticker
  onPressOrder?: (order: BidOrAsk) => void
}

const MIN_HEIGHT = 250
const MAX_HEIGHT = 500

const OptionsOrderbookFooter = ({
  limitPrice,
  defaultLimitPrice,
  size,
  isBuy,
  ticker,
  marketId,
  onPressOrder,
}: Props) => {
  const [settings, setSettings] = useSettings()

  const handleChangeView = (view: TradeFormView) => {
    setSettings((settings) => ({
      ...settings,
      tradeFormView: view,
    }))
  }
  const { subaccount } = useSubaccount()
  const position: Position | undefined = useMemo(() => {
    if (subaccount) {
      const position = subaccount.positions.find(
        (position) => position.instrument_name === ticker.instrument_name
      )
      if (position && +position.amount !== 0) {
        return position
      }
    }
  }, [subaccount, ticker])

  const isPayoffEnabled = !position

  const tradeFormView =
    settings.tradeFormView === TradeFormView.Payoff && !isPayoffEnabled
      ? TradeFormView.Orderbook
      : settings.tradeFormView

  return (
    <>
      <Section.YStack>
        <ButtonGroup>
          {isPayoffEnabled ? (
            <Button
              size="sm"
              label="Payoff"
              isSelected={tradeFormView === TradeFormView.Payoff}
              onPress={() => handleChangeView(TradeFormView.Payoff)}
            />
          ) : null}
          <Button
            size="sm"
            label="Book"
            isSelected={tradeFormView === TradeFormView.Orderbook}
            onPress={() => handleChangeView(TradeFormView.Orderbook)}
          />
          <Button
            size="sm"
            label="Trades"
            isSelected={tradeFormView === TradeFormView.Trades}
            onPress={() => handleChangeView(TradeFormView.Trades)}
          />
        </ButtonGroup>
      </Section.YStack>
      {tradeFormView === TradeFormView.Payoff && isPayoffEnabled ? (
        <OptionsOrderbookPayoffRows
          minHeight={MIN_HEIGHT}
          maxHeight={MAX_HEIGHT}
          paddingBottom="$3"
          ticker={ticker}
          isBuy={isBuy}
          size={size}
          limitPrice={limitPrice}
          defaultLimitPrice={defaultLimitPrice}
        />
      ) : tradeFormView === TradeFormView.Orderbook ? (
        <Section.Table
          minHeight={MIN_HEIGHT}
          maxHeight={MAX_HEIGHT}
          flexGrow={1}
          flexShrink={1}
          width="100%"
        >
          <Orderbook
            height="100%"
            width="100%"
            marketId={marketId}
            instrumentName={ticker.instrument_name}
            tickGroup={getDefaultGroup()}
            tickSize={+ticker.tick_size}
            amountStep={+ticker.amount_step}
            numTicks={4}
            onPressOrder={onPressOrder}
          />
        </Section.Table>
      ) : tradeFormView === TradeFormView.Trades ? (
        <Section.Table
          minHeight={MIN_HEIGHT}
          maxHeight={MAX_HEIGHT}
          flexGrow={1}
          flexShrink={1}
          width="100%"
        >
          <TradeFeed
            height="100%"
            width="100%"
            instrumentName={ticker.instrument_name}
            tickSize={+ticker.tick_size}
            stepSize={+ticker.amount_step}
          />
        </Section.Table>
      ) : null}
    </>
  )
}

export default OptionsOrderbookFooter
