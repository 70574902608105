import TextInterDEPRECATED from '@lyra/core/components/TextInterDEPRECATED'
import React, { ReactElement, useCallback } from 'react'
import {
  Area,
  AreaChart as RechartsAreaChart,
  AreaProps,
  Rectangle,
  ReferenceLine,
  ReferenceLineProps as RechartsReferenceLineProps,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts'
import { CategoricalChartState } from 'recharts/types/chart/generateCategoricalChart'
import { AxisDomain as RechartsAxisDomain, Margin } from 'recharts/types/util/types'
import { Stack, useTheme } from 'tamagui'

import BodyText from './BodyText'

export type ReferenceLineProps = RechartsReferenceLineProps
export type AxisDomain = RechartsAxisDomain

export type CustomAreaChartKey<AreaData> = {
  label: string
  key: keyof AreaData
  strokeDasharray?: string
  activeDot?: (payload: AreaData) => ReactElement<SVGElement>
  stackId?: string
}

export type AreaChartDataPoint = Record<string, any>

type Props<T extends AreaChartDataPoint> = {
  domain?: AxisDomain
  range?: AxisDomain
  data: T[]
  dataKeys: CustomAreaChartKey<T>[]
  type: AreaProps['type']
  hideXAxis?: boolean
  renderTooltip?: (payload: T) => React.ReactNode | string
  onClickArea?: (key: string) => void
  onHover?: (payload: T | null) => void
  onMouseLeave?: () => void
  referenceLinesProps?: ReferenceLineProps[]
  chartMargin?: Margin
  xAxisDataKey?: string
  fallback?: string
  activeDotColor?: any
  stroke?: string
  strokeWidth?: number
  fill?: string
  fillOpacity?: string
  linearGradient?: React.ReactNode
}

export default function AreaChart<T extends AreaChartDataPoint>({
  data,
  dataKeys,
  domain,
  range,
  hideXAxis = true,
  onHover,
  renderTooltip,
  onMouseLeave,
  referenceLinesProps,
  type,
  chartMargin,
  xAxisDataKey = 'x',
  fallback = 'Not enough data',
  fill = '',
  fillOpacity = '50%',
  activeDotColor,
  stroke = 'none',
  strokeWidth = 1,
  linearGradient,
}: Props<T>): JSX.Element {
  const theme = useTheme()
  const activeDotFillColor = activeDotColor ? activeDotColor : theme.greenLineChart?.get()
  const labelColor = theme.secondaryText?.get()
  const handleMouseMove = useCallback(
    (chartState: CategoricalChartState) => {
      if (!chartState || !chartState.activePayload || chartState.activePayload.length === 0) {
        if (onMouseLeave) {
          onMouseLeave()
        }
        return
      }
      if (onHover) {
        onHover(chartState.activePayload[0].payload)
      }
    },
    [onHover]
  )

  if (data.length <= 1) {
    return (
      <Stack justifyContent="center" height="100%">
        <BodyText color="secondary">{fallback}</BodyText>
      </Stack>
    )
  }

  return (
    <Stack height="100%" width="100%" alignSelf="flex-start">
      <ResponsiveContainer width="100%" height="100%">
        <RechartsAreaChart
          data={data}
          margin={chartMargin}
          onMouseLeave={() => {
            if (onHover) {
              onHover(null)
            }
            if (onMouseLeave) {
              onMouseLeave()
            }
          }}
          onMouseDown={handleMouseMove}
          onMouseMove={handleMouseMove}
        >
          {hideXAxis ? null : <ReferenceLine y={0} stroke={labelColor} />}
          <XAxis
            hide={true}
            dataKey={xAxisDataKey}
            type="number"
            domain={domain ?? ['dataMin', 'dataMax']}
          />
          <YAxis hide={true} type="number" domain={range ?? ['dataMin', 'dataMax']} />
          {linearGradient ? <defs>{linearGradient}</defs> : null}
          {referenceLinesProps
            ? referenceLinesProps.map((referenceLineProps) => (
                <ReferenceLine key={referenceLineProps.id} {...referenceLineProps} />
              ))
            : null}
          <Tooltip
            cursor={{
              stroke: theme.primary?.get(),
            }}
            allowEscapeViewBox={{ x: true, y: true }}
            isAnimationActive={false}
            offset={0}
            active={true}
            content={(prop) => {
              if (prop.payload && prop.payload.length && renderTooltip) {
                const tooltip = renderTooltip(prop.payload[0].payload)
                return typeof tooltip === 'string' ? (
                  <TextInterDEPRECATED>{tooltip}</TextInterDEPRECATED>
                ) : (
                  tooltip
                )
              }
              return null
            }}
            position={{ y: 0 }}
          />

          {dataKeys.map((dataKey) => {
            return (
              <Area
                key={dataKey.key.toString()}
                dataKey={dataKey.key.toString()}
                isAnimationActive={false}
                dot={false}
                activeDot={(props: any) => {
                  const { cx, cy } = props
                  return (
                    <Rectangle
                      radius={1}
                      x={cx - 6}
                      y={cy - 6}
                      height={12}
                      width={12}
                      fill={activeDotFillColor}
                      color={activeDotFillColor}
                    />
                  )
                }}
                type={type}
                stroke={stroke}
                strokeWidth={strokeWidth}
                fill={fill}
                fillOpacity={fillOpacity}
                stackId={dataKey.stackId}
              />
            )
          })}
        </RechartsAreaChart>
      </ResponsiveContainer>
    </Stack>
  )
}
