import { LyraAuthHeaders } from '@lyra/core/constants/api'

import tryRequest, { RequestOptions } from '../tryRequest'
import {
  PrivateRfqGetBestQuoteParamsSchema,
  PrivateRfqGetBestQuoteResponseSchema,
} from '../types/private.rfq_get_best_quote'

export default async function fetchRfqBestQuote(
  params: PrivateRfqGetBestQuoteParamsSchema,
  authHeaders: LyraAuthHeaders,
  options?: RequestOptions
): Promise<PrivateRfqGetBestQuoteResponseSchema> {
  return tryRequest<PrivateRfqGetBestQuoteParamsSchema, PrivateRfqGetBestQuoteResponseSchema>(
    '/private/rfq_get_best_quote',
    {
      label: params.label,
      legs: params.legs,
      max_total_cost: params.max_total_cost,
      min_total_cost: params.min_total_cost,
      subaccount_id: params.subaccount_id,
      rfq_id: params.rfq_id,
      // always buy on client side, legs dictate overall buy/sell
      direction: params.direction,
    },
    {
      ...options,
      authHeaders,
    }
  )
}
