import fetchRfqBestQuote from '@lyra/core/api/private/fetchRfqBestQuote'
import {
  LegUnpricedSchema,
  PrivateRfqGetBestQuoteParamsSchema,
  PrivateRfqGetBestQuoteResultSchema,
} from '@lyra/core/api/types/private.rfq_get_best_quote'
import { LyraAuthHeaders } from '@lyra/core/constants/api'
import useSWR from 'swr'

import { MarketId } from '../constants/markets'
import { RfqBestQuote, SendRfqParams, SendRfqResponse } from '../constants/rfqs'
import getAuthHeaders from '../utils/getAuthHeaders'
import useAuth from './useAuth'
import useOrderbookTimestamp from './useOrderbookTimestamp'
import useSubaccount from './useSubaccount'

export type HookParams = {
  legs: LegUnpricedSchema[]
  limitPrice?: number
  size: number
  marketId: MarketId
}

export type RfqState = {
  error: string | undefined
  params?: PrivateRfqGetBestQuoteParamsSchema
  bestQuoteEstimate?: PrivateRfqGetBestQuoteResultSchema
  isLoading: boolean
}

const fetcher = async (
  subaccountId: number,
  sendRfqParams: SendRfqParams,
  authHeaders: LyraAuthHeaders
): Promise<RfqBestQuote> => {
  const params: PrivateRfqGetBestQuoteParamsSchema = {
    ...sendRfqParams,
    subaccount_id: subaccountId,
  }

  const { result } = await fetchRfqBestQuote(params, authHeaders)

  return result
}

export default function useRfqBestQuote(sendRfqParams: SendRfqParams | SendRfqResponse) {
  const { user } = useAuth()
  const { subaccount } = useSubaccount()

  const subaccountId = subaccount?.subaccount_id
  const authHeaders = user?.hasAuth ? getAuthHeaders(user) : null

  const { getTimestamp } = useOrderbookTimestamp()

  const timeToExpiry =
    'valid_until' in sendRfqParams
      ? Math.max(0, sendRfqParams.valid_until - getTimestamp())
      : Infinity

  return useSWR<RfqBestQuote | null, Error>(
    ['RfqQuoteEstimate', subaccountId, sendRfqParams, authHeaders],
    () =>
      subaccountId && authHeaders && timeToExpiry > 0
        ? fetcher(subaccountId, sendRfqParams, authHeaders)
        : null,
    { refreshInterval: 1000, keepPreviousData: true }
  )
}
