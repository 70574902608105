import { parseAsFloat, useQueryState } from 'next-usequerystate'
import { useCallback, useMemo } from 'react'

import { QueryParam } from '../constants/query'
import { roundToStep } from '../utils/number'

export default function useTradeSizeInput(stepSize?: number): [number, (size: number) => void] {
  const [_size, _setSize] = useQueryState(QueryParam.Size, parseAsFloat)
  const size = _size ? _size : 0
  const sizeRounded = stepSize ? roundToStep(size, stepSize) : size
  const setSize = useCallback(
    (size: number) => {
      if (size === 0) {
        _setSize(null)
        return
      }
      const sizeRounded = stepSize ? roundToStep(size, stepSize) : size
      _setSize(sizeRounded)
    },
    [_setSize, stepSize]
  )
  return useMemo(() => [sizeRounded, setSize], [sizeRounded, setSize])
}
