import { BodyTextSize, getTextColorVar, TextColor } from '@lyra/core/utils/text'
import React, { forwardRef } from 'react'
import { FontSizeTokens, SizableText, SizableTextProps } from 'tamagui'

export type TextInterDEPRECATEDProps = {
  // props
  color?: TextColor
  size?: BodyTextSize
  isBold?: boolean
} & Omit<
  SizableTextProps,
  | 'fontFamily'
  | 'fontSize'
  | 'lineHeight'
  | 'weight'
  | 'letterSpacing'
  | 'size'
  | 'textTransform'
  | 'color'
>

// Replace with BodyText
const TextInterDEPRECATED = forwardRef<HTMLElement, TextInterDEPRECATEDProps>(
  (
    { children, color = 'primary', size = 'md', isBold, ...styleProps }: TextInterDEPRECATEDProps,
    ref
  ) => {
    const sizeToken = ('$' + size) as FontSizeTokens
    const colorVar = getTextColorVar(color)

    return (
      <SizableText
        {...styleProps}
        ref={ref}
        fontFamily="$body"
        size={sizeToken}
        color={colorVar}
        style={{
          fontWeight: isBold ? 500 : 400,
          letterSpacing: isBold ? '0.01em' : 0,
          ...(styleProps.style as React.CSSProperties),
        }}
      >
        {children}
      </SizableText>
    )
  }
)

TextInterDEPRECATED.displayName = 'Text'

export default TextInterDEPRECATED
