import {
  basicTwoLegStrategies,
  butterflyStrategies,
  condorStrategies,
  LegData,
  OptionsStrategy,
} from '../constants/strategies'

const getAreLegsSameExpiry = (legs: LegData[]) =>
  legs.slice(1).every((leg) => leg.parsed.expiry.getTime() === legs[0].parsed.expiry.getTime())
const getAreLegsSameRatio = (legs: LegData[]) =>
  legs.slice(1).every((leg) => leg.leg.amount === legs[0].leg.amount)

const getAreLegsEquidistant = (
  legs: [LegData, LegData] | [LegData, LegData, LegData] | [LegData, LegData, LegData, LegData]
): boolean => {
  const differences = legs
    .slice(1)
    .map((leg, index) => leg.parsed.strikePrice - legs[index].parsed.strikePrice)
  return differences.every((diff) => diff === differences[0])
}

function getTwoLegCalendarStrategy(legs: [LegData, LegData]): OptionsStrategy | undefined {
  return undefined
}

function getTwoLegStrategy(
  legs: [LegData, LegData],
  strategyKey: string
): OptionsStrategy | undefined {
  if (!getAreLegsSameExpiry(legs)) {
    return getTwoLegCalendarStrategy(legs)
  }
  if (!getAreLegsSameRatio(legs)) {
    return OptionsStrategy.Custom
  }
  if (legs[0].parsed.isCall === legs[1].parsed.isCall) {
    return basicTwoLegStrategies[strategyKey]
  }
  return strategyKey === 'D:BCBP' && legs[0].parsed.strikePrice === legs[1].parsed.strikePrice
    ? OptionsStrategy.LongStraddle
    : strategyKey === 'D:BPBC'
    ? OptionsStrategy.LongStrangle
    : undefined
}

function getThreeLegStrategy(
  legs: [LegData, LegData, LegData],
  strategyKey: string
): OptionsStrategy | undefined {
  if (!getAreLegsSameExpiry(legs)) {
    return undefined
  }
  // equidistant with 1:2:1 ratio
  if (
    legs[0].leg.amount === legs[2].leg.amount &&
    +legs[0].leg.amount * 2 === +legs[1].leg.amount &&
    getAreLegsEquidistant(legs)
  ) {
    return butterflyStrategies[strategyKey]
  }
}

function getFourLegStrategy(
  legs: [LegData, LegData, LegData, LegData],
  strategyKey: string
): OptionsStrategy | undefined {
  if (!getAreLegsSameExpiry(legs) || !getAreLegsSameRatio(legs)) {
    return undefined
  }
  if (getAreLegsEquidistant(legs)) {
    return condorStrategies[strategyKey]
  }
  if (
    legs[1].parsed.strikePrice === legs[2].parsed.strikePrice &&
    getAreLegsEquidistant([legs[0], legs[2], legs[3]])
  ) {
    return butterflyStrategies[strategyKey]
  }
}

export function getStrategy(legs: LegData[], strategyKey: string) {
  switch (legs.length) {
    case 1:
      return legs[0].parsed.isCall
        ? legs[0].leg.direction === 'buy'
          ? OptionsStrategy.LongCall
          : OptionsStrategy.ShortCall
        : legs[0].leg.direction === 'buy'
        ? OptionsStrategy.LongPut
        : OptionsStrategy.ShortPut
    case 2:
      return getTwoLegStrategy([legs[0], legs[1]], strategyKey)
    case 3:
      return getThreeLegStrategy([legs[0], legs[1], legs[2]], strategyKey)
    case 4:
      return getFourLegStrategy([legs[0], legs[1], legs[2], legs[3]], strategyKey)
  }
}

export function formatStrategyName(optionsStrategy: OptionsStrategy | undefined): string {
  switch (optionsStrategy) {
    case 'ShortIronCondor':
      return 'Short Iron Condor'
    case 'LongIronCondor':
      return 'Long Iron Condor'
    case 'ShortPutCondor':
      return 'Short Put Condor'
    case 'LongPutCondor':
      return 'Long Put Condor'
    case 'ShortCallCondor':
      return 'Short Call Condor'
    case 'LongCallCondor':
      return 'Long Call Condor'
    case 'ShortIronButterfly':
      return 'Short Iron Butterfly'
    case 'LongIronButterfly':
      return 'Long Iron Butterfly'
    case 'ShortPutButterfly':
      return 'Short Put Butterfly'
    case 'LongPutButterfly':
      return 'Long Put Butterfly'
    case 'ShortCallButterfly':
      return 'Short Call Butterfly'
    case 'LongCallButterfly':
      return 'Long Call Butterfly'
    case 'PutDebit':
      return 'Long Put Spread'
    case 'PutCredit':
      return 'Short Put Spread'
    case 'CallDebit':
      return 'Long Call Spread'
    case 'CallCredit':
      return 'Short Call Spread'
    case 'LongStraddle':
      return 'Long Straddle'
    case 'LongStrangle':
      return 'Long Strangle'
    case 'PutRatioBack':
      return 'Put Ratio Back'
    case 'PutRatioFront':
      return 'Put Ratio Front'
    case 'CallRatioBack':
      return 'Call Ratio Back'
    case 'ShortCall':
      return 'Short Call'
    case 'LongCall':
      return 'Long Call'
    case 'ShortPut':
      return 'Short Put'
    case 'LongPut':
      return 'Long Put'
    case 'Ratio':
      return 'Ratio Spread'
    default:
      return 'Custom'
  }
}
