/* eslint-disable react/react-in-jsx-scope */
import { useTheme, YStack } from '@lyra/core/components'
import AreaChart, { ReferenceLineProps } from '@lyra/core/components/AreaChart'
import BodyText from '@lyra/core/components/BodyText'
import formatUSD from '@lyra/core/utils/formatUSD'
import { getOptionProjectedSettlePnl } from '@lyra/web/src/utils/order'
import React, { useCallback, useEffect, useMemo, useState } from 'react'

type PayoffDataPoint = {
  x: number
  payoff: number
}

export type PayoffChartOptionData = {
  isCall: boolean
  strikePrice: number
  isBuy: boolean
  pricePerOption: number
  size?: number | null
}

type Props = {
  marketName: string
  optionsData: PayoffChartOptionData[]
  currentSpotPrice: number
  expectedPayoff: number
  onHover?: (point: PayoffDataPoint | null) => void
  height?: number | string
  tooltipFontSize?: number
  payoffAdjustment?: number
}

const NUM_PAYOFF_CHART_POINTS = 400

const SPOT_RANGE = 1.1
const MIN_MARGIN_MULTIPLIER = 0.98
const MAX_MARGIN_MULTIPLIER = 1.02

function calculateChartData(
  optionsData: PayoffChartOptionData[],
  numPoints: number,
  spotPrice: number,
  _payoffAdjustment?: number
): PayoffDataPoint[] {
  const payoffAdjustment = _payoffAdjustment ?? 0
  const minStrike = +optionsData[0].strikePrice
  const maxStrikePrice = +optionsData[optionsData.length - 1].strikePrice

  const chartMin = Math.min(minStrike, spotPrice / SPOT_RANGE) * MIN_MARGIN_MULTIPLIER
  const chartMax = Math.max(maxStrikePrice, spotPrice * SPOT_RANGE) * MAX_MARGIN_MULTIPLIER

  const pointInterval = (chartMax - chartMin) / numPoints

  const payoffMap = new Map<number, number>()

  Array.from({ length: numPoints }, (_, index) => {
    const xValue = chartMin + index * pointInterval
    const totalPayoff = optionsData.reduce((acc, option) => {
      return (
        acc +
        getOptionProjectedSettlePnl(
          option.isBuy,
          option.isCall,
          option.strikePrice,
          xValue,
          option.pricePerOption,
          option.size && option.size > 0 ? option.size : 1
        )
      )
    }, 0)
    payoffMap.set(xValue, totalPayoff)
  })

  return Array.from(payoffMap.entries()).map(([x, payoff]) => ({
    x,
    payoff: payoff + payoffAdjustment,
  }))
}

function calculateOffsets(
  data: PayoffDataPoint[],
  greenFillColor: string,
  redFillColor: string
): { offsets: number[]; colors: string[] } {
  let currSign = Math.sign(data[0].payoff)
  const offsets: number[] = []
  const colors: string[] = []

  data.forEach((point, index) => {
    if (index === 0) {
      offsets.push(0)
      colors.push(currSign > 0 ? greenFillColor : redFillColor)
    } else if (index === data.length - 1) {
      offsets.push(1)
      colors.push(currSign > 0 ? greenFillColor : redFillColor)
    } else if (currSign !== Math.sign(point.payoff) && Math.sign(point.payoff) !== 0) {
      offsets.push(index / data.length)
      offsets.push(index / data.length)
      colors.push(currSign > 0 ? greenFillColor : redFillColor)
      colors.push(currSign > 0 ? redFillColor : greenFillColor)
      currSign = Math.sign(point.payoff)
    }
  })
  return {
    offsets,
    colors,
  }
}

const PayoffChart = ({
  optionsData,
  currentSpotPrice,
  expectedPayoff,
  onHover,
  tooltipFontSize = 11,
  marketName,
  payoffAdjustment,
}: Props) => {
  const theme = useTheme()
  const [hoverPayoff, setHoverPayoff] = useState<number | null>(null)
  const [hoveredPoint, setHoveredPoint] = useState<PayoffDataPoint | null>(null)

  const handleHover = useCallback(
    (pt: PayoffDataPoint | null) => {
      setHoveredPoint(pt)
      setHoverPayoff(pt?.payoff ?? null)
      onHover?.(pt)
    },
    [onHover]
  )

  // Styles and colors
  const activeDotColor =
    expectedPayoff >= 0 ? theme.greenLineChart?.get() : theme.redLineChart?.get()
  const textColor = theme.primaryText?.get()
  const greenFillColor = theme.greenAreaChart?.get()
  const redFillColor = theme.redAreaChart?.get()

  // Data calculation
  const data = useMemo(
    () =>
      calculateChartData(optionsData, NUM_PAYOFF_CHART_POINTS, currentSpotPrice, payoffAdjustment),
    [optionsData, currentSpotPrice, payoffAdjustment]
  )

  const isHovering = hoverPayoff !== null
  const referenceLines = useMemo(() => {
    const x = hoveredPoint?.x ?? currentSpotPrice
    const referenceLines: ReferenceLineProps[] = []
    referenceLines.push({
      id: 'hoverSpotPrice',
      opacity: 1,
      x,
      stroke: textColor,
      label: ({ viewBox }: { viewBox: { x: number; y: number } }) => {
        return (
          <svg opacity={1} x={viewBox.x - 50} y={tooltipFontSize} overflow="visible" width="100px">
            <text
              opacity={isHovering ? 0 : 1}
              x="50%"
              y={-0.5}
              fill={textColor}
              dominantBaseline="middle"
              textAnchor="middle"
              fontFamily="GT Standard"
              fontWeight="500"
              fontSize={tooltipFontSize}
            >
              {marketName.toUpperCase()} Price
            </text>
            <text
              opacity={isHovering ? 0 : 1}
              x="50%"
              y={tooltipFontSize + 5.5}
              fill={textColor}
              dominantBaseline="middle"
              textAnchor="middle"
              fontFamily="GT Standard"
              fontWeight="500"
              fontSize={tooltipFontSize}
            >
              {formatUSD(currentSpotPrice, { showCommas: false })}
            </text>
          </svg>
        )
      },
    })
    return referenceLines
  }, [hoveredPoint?.x, currentSpotPrice, textColor, tooltipFontSize, isHovering, marketName])

  const { offsets, colors } = useMemo(
    () => calculateOffsets(data, greenFillColor, redFillColor),
    [data, greenFillColor, redFillColor]
  )

  // update hover payoff if payoffAdjustment changes while also hovering
  useEffect(() => {
    if (hoveredPoint) {
      // find the closest data point to hoveredPoint.x to account for floating-point precision
      const closestPoint = data.reduce((prev, curr) =>
        Math.abs(curr.x - hoveredPoint.x) < Math.abs(prev.x - hoveredPoint.x) ? curr : prev
      )
      const updatedPayoff = closestPoint?.payoff ?? null
      setHoverPayoff(updatedPayoff)
      if (onHover) onHover(closestPoint)
    }
  }, [payoffAdjustment, data, hoveredPoint, onHover])

  return (
    <AreaChart
      type="linear"
      dataKeys={[{ label: 'Payoff', key: 'payoff' }]}
      domain={['dataMin', 'dataMax']}
      // add 1 to max and min to prevent boxes from breaking the chart
      range={['dataMin - 1', 'dataMax + 1']}
      data={data}
      onHover={handleHover}
      onMouseLeave={() => handleHover(null)}
      chartMargin={{
        top: 40,
        bottom: 8,
      }}
      renderTooltip={({ x }) => (
        <YStack marginLeft="-50%">
          <BodyText size="sm">{marketName.toUpperCase()} Price</BodyText>
          <BodyText size="sm">{formatUSD(x, { showCommas: false })}</BodyText>
        </YStack>
      )}
      hideXAxis={true}
      referenceLinesProps={referenceLines}
      fallback="Something went wrong"
      activeDotColor={activeDotColor}
      stroke={'url(#splitColor)'}
      strokeWidth={2}
      fill={'url(#splitColor)'}
      linearGradient={
        <linearGradient id="splitColor" x1="0" y1="0" x2="1" y2="0">
          {offsets.map((offset, index) => (
            <stop key={index} offset={offset} stopColor={colors[index]} stopOpacity={1} />
          ))}
        </linearGradient>
      }
    />
  )
}

export default PayoffChart
