import Button, { ButtonColor } from '@lyra/core/components/Button'
import Notice from '@lyra/core/components/Notice'
import Section from '@lyra/core/components/Section'
import isServer from '@lyra/core/utils/isServer'
import { Ticker } from '@lyra/web/constants/instruments'
import { Order, OrderParamsWithoutSignature, Quote } from '@lyra/web/constants/order'
import { Position } from '@lyra/web/constants/position'
import { QueryParam, QueryPositionTab } from '@lyra/web/constants/query'
import useAuth from '@lyra/web/hooks/useAuth'
import useSubaccount from '@lyra/web/hooks/useSubaccount'
import useTransaction from '@lyra/web/hooks/useTransaction'
import { getTransactionDisabledMessage } from '@lyra/web/utils/wallet'
import { parseAsStringEnum, useQueryState } from 'next-usequerystate'
import { useCallback, useMemo, useState } from 'react'
import { StackProps } from 'tamagui'

import EnableTradingButtonRow from '../common/EnableTradingButtonRow'
import FundTradingButtonRow from '../common/FundTradingButtonRow'
import SignInButton from '../common/SignInButton'
import OrderbookWarningNotice from './OrderbookWarningNotice'

type Props = {
  ticker: Ticker
  orderParams: OrderParamsWithoutSignature
  quote?: Quote
  error?: string
  color?: ButtonColor
  isLoading?: boolean
  onSubmit?: (order: Order) => void
  onCancel?: () => void
} & StackProps

export default function OrderbookSubmitButtonRow({
  ticker,
  orderParams,
  quote,
  error: quoteError,
  color = 'green',
  isLoading,
  onSubmit,
  onCancel,
  ...stackProps
}: Props) {
  const { isAuthenticated, accountDisabledReason } = useAuth()
  const { sessionKey } = useTransaction()
  const { subaccount, isEmpty: subaccountIsEmpty, submitOrder } = useSubaccount()

  const [isSubmitting, setIsSubmitting] = useState(false)
  const [orderError, setOrderError] = useState<string>()

  const [_0, setPositionTab] = useQueryState(
    QueryParam.PositionTab,
    parseAsStringEnum(Object.values(QueryPositionTab))
  )

  const handleSubmitOrder = useCallback(async () => {
    setOrderError(undefined)
    setIsSubmitting(true)
    try {
      const order = await submitOrder(
        ticker.base_asset_sub_id,
        ticker.base_asset_address,
        orderParams
      )
      setOrderError(undefined)
      if (order.order_status === 'filled') {
        // open positions tab on instantly filled order
        setPositionTab(QueryPositionTab.Positions)
      } else if (order.order_status === 'open') {
        // open orders tab on open (limit) order
        setPositionTab(QueryPositionTab.Orders)
      } else if (order.order_status === 'cancelled') {
        throw new Error('Order cancelled.')
      }
      setIsSubmitting(false)
      if (onSubmit) {
        onSubmit(order)
      }
    } catch (error: unknown) {
      console.error(error)
      setOrderError((error as any)?.message ?? 'Failed to submit order')
      setIsSubmitting(false)
    }
  }, [orderParams, ticker.base_asset_sub_id, onSubmit, submitOrder, setPositionTab])

  const isBuy = orderParams.direction === 'buy'

  const isUnsupportedCurrency =
    subaccount && subaccount.margin_type === 'PM' && subaccount.currency !== ticker.base_currency

  const position: Position | undefined = useMemo(() => {
    if (subaccount) {
      const position = subaccount.positions.find(
        (position) => position.instrument_name === ticker.instrument_name
      )
      if (position && +position.amount !== 0) {
        return position
      }
    }
  }, [subaccount, ticker])

  const tradeSize = isBuy ? +orderParams.amount : -+orderParams.amount
  const positionSize = position ? +position.amount : 0
  const postTradePositionSize = tradeSize + positionSize

  const orderButtonMessage =
    quote && !quote.is_valid
      ? 'Insufficient Balance'
      : quote?.estimated_order_status === 'filled'
      ? postTradePositionSize === 0
        ? 'Close Position'
        : 'Open Position'
      : 'Submit Order'

  const isDisabled = !quote?.is_valid || isUnsupportedCurrency || !!quoteError

  if (isServer) {
    return (
      <Section.YStack {...stackProps}>
        <Button size="lg" isLoading />
      </Section.YStack>
    )
  }

  if (!isAuthenticated) {
    return (
      <Section.YStack {...stackProps}>
        <SignInButton isCta size="lg" width="100%" />
      </Section.YStack>
    )
  }

  if (accountDisabledReason) {
    return (
      <Section.YStack gap="$2" {...stackProps}>
        <Notice
          message={getTransactionDisabledMessage(accountDisabledReason)}
          status="error"
          width="100%"
        />
        <Button isDisabled size="lg" width="100%" label={orderButtonMessage} />
      </Section.YStack>
    )
  }

  if (subaccountIsEmpty) {
    return <FundTradingButtonRow width="100%" />
  }

  if (!sessionKey) {
    return <EnableTradingButtonRow width="100%" />
  }

  return (
    <Section.YStack gap="$2" {...stackProps}>
      <OrderbookWarningNotice
        ticker={ticker}
        limitPrice={+orderParams.limit_price}
        isBuy={isBuy}
        quote={quote}
        error={orderError ?? quoteError}
      />
      <Button
        isDisabled={isDisabled}
        onPress={handleSubmitOrder}
        size="lg"
        isSolid
        color={color}
        label={!isLoading ? orderButtonMessage : null}
        width="100%"
        isLoading={isSubmitting || isLoading}
      />
      {onCancel ? <Button size="lg" label="Go Back" width="100%" onPress={onCancel} /> : null}
    </Section.YStack>
  )
}
