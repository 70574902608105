import React from 'react'
import { StackProps, XStack } from 'tamagui'

import BodyText from './BodyText'
import LabelText from './LabelText'

type Props = {
  // TODO: @earthtojake support tooltips
  label: React.ReactNode
  value: React.ReactNode
} & StackProps

export default function DataLabelRow({ label, value, ...stackProps }: Props) {
  return (
    <XStack justifyContent="space-between" alignItems="center" {...stackProps}>
      <LabelText>{label}</LabelText>
      <BodyText>{value}</BodyText>
    </XStack>
  )
}
