import Button, { ButtonColor } from '@lyra/core/components/Button'
import { ArrowRight } from '@lyra/core/components/Icon'
import Notice from '@lyra/core/components/Notice'
import Section from '@lyra/core/components/Section'
import isServer from '@lyra/core/utils/isServer'
import { Ticker } from '@lyra/web/constants/instruments'
import { OrderParamsWithoutSignature, Quote } from '@lyra/web/constants/order'
import useAuth from '@lyra/web/hooks/useAuth'
import useSubaccount from '@lyra/web/hooks/useSubaccount'
import useTransaction from '@lyra/web/hooks/useTransaction'
import { getTransactionDisabledMessage } from '@lyra/web/utils/wallet'
import { StackProps } from 'tamagui'

import EnableTradingButtonRow from '../common/EnableTradingButtonRow'
import FundTradingButtonRow from '../common/FundTradingButtonRow'
import SignInButton from '../common/SignInButton'
import OrderbookWarningNotice from './OrderbookWarningNotice'

type Props = {
  ticker: Ticker
  size: number
  isBuy: boolean
  limitPrice: number
  quote?: Quote
  orderParams?: OrderParamsWithoutSignature
  error?: string
  color?: ButtonColor
  isLoading?: boolean
  onPressReview: (orderParams: OrderParamsWithoutSignature) => void
} & StackProps

export default function OrderbookReviewButtonRow({
  ticker,
  size,
  isBuy,
  limitPrice,
  quote,
  orderParams,
  error,
  color = 'green',
  isLoading,
  onPressReview,
  ...stackProps
}: Props) {
  const { isAuthenticated, accountDisabledReason } = useAuth()
  const { sessionKey } = useTransaction()
  const { subaccount, isEmpty: subaccountIsEmpty } = useSubaccount()

  const isUnsupportedCurrency =
    subaccount && subaccount.margin_type === 'PM' && subaccount.currency !== ticker.base_currency

  const invalidTPSLParams =
    orderParams && !!orderParams.trigger_type && Number(orderParams.trigger_price) === 0

  const reviewButtonMessage =
    size === 0
      ? 'Enter Amount'
      : quote && !quote.is_valid
      ? 'Insufficient Balance'
      : invalidTPSLParams
      ? 'Enter Trigger Price'
      : 'Review Order'

  const isDisabled =
    !quote?.is_valid || !orderParams || isUnsupportedCurrency || !!error || invalidTPSLParams

  if (isServer) {
    return (
      <Section.YStack {...stackProps}>
        <Button size="lg" isLoading />
      </Section.YStack>
    )
  }

  if (!isAuthenticated) {
    return (
      <Section.YStack {...stackProps}>
        <SignInButton isCta size="lg" width="100%" />
      </Section.YStack>
    )
  }

  if (accountDisabledReason) {
    return (
      <Section.YStack {...stackProps}>
        <Notice
          message={getTransactionDisabledMessage(accountDisabledReason)}
          status="error"
          width="100%"
        />
        <Button isDisabled size="lg" width="100%" label={reviewButtonMessage} />
      </Section.YStack>
    )
  }

  if (subaccountIsEmpty) {
    return <FundTradingButtonRow width="100%" />
  }

  if (!sessionKey) {
    return <EnableTradingButtonRow width="100%" />
  }

  const isReviewAvailable = size > 0 && quote && quote.is_valid
  return (
    <Section.YStack {...stackProps}>
      <OrderbookWarningNotice
        ticker={ticker}
        limitPrice={limitPrice}
        isBuy={isBuy}
        orderParams={orderParams}
        quote={quote}
        error={error}
      />
      <Button
        isDisabled={isDisabled}
        onPress={() => (orderParams ? onPressReview(orderParams) : null)}
        size="lg"
        color={color}
        label={!isLoading ? reviewButtonMessage : null}
        width="100%"
        isOutlined
        isLoading={isLoading}
        icon={isReviewAvailable ? <ArrowRight /> : null}
      />
    </Section.YStack>
  )
}
