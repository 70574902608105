import BodyText from '@lyra/core/components/BodyText'
import Button from '@lyra/core/components/Button'
import { X } from '@lyra/core/components/Icon'
import IconSquare from '@lyra/core/components/IconSquare'
import NumberInput from '@lyra/core/components/Input/NumberInput'
import Section from '@lyra/core/components/Section'
import formatUSD from '@lyra/core/utils/formatUSD'
import MarketIcon from '@lyra/web/components/common/MarketIcon'
import { MARKET_ICON_SIZE } from '@lyra/web/constants/layout'
import { DEFAULT_RFQ_RATIO } from '@lyra/web/constants/rfqs'
import {
  formatInstrument,
  formatOptionExpiry,
  parseOptionFromInstrumentName,
} from '@lyra/web/utils/instruments'
import React, { useState } from 'react'
import { XStack, YStack } from 'tamagui'

type Props = {
  instrumentName: string
  isBuy: boolean
  price: number
  showPriceSign?: boolean
  isEditingRatio?: boolean
  ratio?: number
  showRatio?: boolean
  onChangeRatio?: (amount: number) => void
  onDeselect?: () => void
}

const SelectedOptionRow = ({
  instrumentName,
  isBuy,
  price,
  showPriceSign,
  isEditingRatio,
  showRatio,
  ratio = DEFAULT_RFQ_RATIO,
  onChangeRatio,
  onDeselect,
}: Props) => {
  const marketId = parseOptionFromInstrumentName(instrumentName)?.marketId
  const [ratioInput, setRatioInput] = useState(DEFAULT_RFQ_RATIO)
  const [isFocus, setIsFocus] = useState(false)

  return (
    <Section.XStack justifyContent="space-between">
      <XStack alignItems="center" gap="$1">
        {isEditingRatio && onChangeRatio ? (
          <NumberInput
            defaultValue={DEFAULT_RFQ_RATIO}
            // on focus, use local input
            value={isFocus ? ratioInput : ratio}
            onChangeValue={setRatioInput}
            width={54}
            // Note: for editing ratios, we only return the value onBlur
            // this preserves other ratios while editing
            onBlur={() => {
              if (ratio <= 0) {
                onChangeRatio(DEFAULT_RFQ_RATIO)
              } else {
                onChangeRatio(ratioInput)
              }
              setIsFocus(false)
            }}
            onFocus={() => {
              // initialize with latest value
              setRatioInput(ratio)
              setIsFocus(true)
            }}
          />
        ) : showRatio ? (
          <IconSquare>
            <XStack justifyContent="center">
              <BodyText>{ratio}</BodyText>
            </XStack>
          </IconSquare>
        ) : marketId ? (
          <MarketIcon size={MARKET_ICON_SIZE} marketId={marketId} />
        ) : null}
        <YStack>
          <BodyText>{formatInstrument(instrumentName)}</BodyText>
          <XStack alignItems="center" gap="$0.5">
            <BodyText color={isBuy ? 'green' : 'red'}>{isBuy ? 'Long' : 'Short'}</BodyText>
            <BodyText color="secondary">{formatOptionExpiry(instrumentName)}</BodyText>
          </XStack>
        </YStack>
      </XStack>
      <XStack alignItems="center" gap="$1">
        {price ? (
          <BodyText>{formatUSD(Math.abs(price) * (showPriceSign ? (isBuy ? 1 : -1) : 1))}</BodyText>
        ) : null}
        {onDeselect ? <Button icon={<X />} isTransparent size="sm" onPress={onDeselect} /> : null}
      </XStack>
    </Section.XStack>
  )
}
export default SelectedOptionRow
