import { YStack } from '@lyra/core/components'
import BodyText from '@lyra/core/components/BodyText'
import Button from '@lyra/core/components/Button'
import NumberInput from '@lyra/core/components/Input/NumberInput'
import Link from '@lyra/core/components/Link'
import Section from '@lyra/core/components/Section'
import { SelectedOption } from '@lyra/web/constants/options'
import { DEFAULT_RFQ_SIZE, SendRfqParams, SendRfqResponse } from '@lyra/web/constants/rfqs'
import useDebounce from '@lyra/web/hooks/useDebounce'
import useRfqBestQuote from '@lyra/web/hooks/useRfqBestQuote'
import useSubaccount from '@lyra/web/hooks/useSubaccount'
import useTickers from '@lyra/web/hooks/useTickers'
import { getDefaultLimitPrice } from '@lyra/web/utils/order'
import { getRfqGcdAmount, getRfqMaxTotalCost } from '@lyra/web/utils/rfqs'
import { useCallback, useMemo, useState } from 'react'

import TradeErrorNotice from '../../TradeErrorNotice'
import TradeExecuteButtonRow from '../../TradeExecuteButtonRow'
import OptionsTradeFormMultiSelectRow from '../OptionsTradeFormMultiselectRow'
import OptionsRfqReceiptRows from './OptionsRfqReceiptRows'
import OptionsRfqTicketRows from './OptionsRfqTicketRows'
import SelectedOptionRow from './SelectedOptionRow'

type Props = {
  sendRfqParams: SendRfqParams
  onChangeRfqParams: (
    sendRfqParams: SendRfqParams | ((old: SendRfqParams) => SendRfqParams | null)
  ) => void
  onSendRfq: (rfq: SendRfqResponse) => void
  onDeselectOption: (option: SelectedOption) => void
}

const OptionsRfqSendSection = ({
  sendRfqParams,
  onChangeRfqParams,
  onSendRfq,
  onDeselectOption,
}: Props) => {
  const { sendRfq, subaccount } = useSubaccount()

  const tickers = useTickers(sendRfqParams.legs.map((leg) => leg.instrument_name))

  const [sendRfqError, setSendRfqError] = useState<Error>()

  const {
    data: bestQuoteEstimate,
    error: quoteError,
    isLoading,
  } = useRfqBestQuote(useDebounce(sendRfqParams, 100))

  const error = sendRfqError ?? quoteError

  const _isCustomRatio = useMemo(() => {
    const firstLeg = sendRfqParams.legs[0]
    if (!firstLeg) {
      return false
    }
    const isSameAmount = sendRfqParams.legs.every((leg) => leg.amount === firstLeg.amount)
    return !isSameAmount
  }, [sendRfqParams])

  const [isDefaultCustomRatio, setIsDefaultCustomRatio] = useState(_isCustomRatio)

  const isCustomRatio = _isCustomRatio ? true : isDefaultCustomRatio

  const amount = useMemo(() => {
    const amount = getRfqGcdAmount(sendRfqParams.legs)
    return amount ? amount : DEFAULT_RFQ_SIZE
  }, [sendRfqParams.legs])

  const legRatios = useMemo(() => {
    return sendRfqParams.legs.reduce(
      (dict, leg) => ({
        ...dict,
        [leg.instrument_name]: amount > 0 ? +leg.amount / amount : 0,
      }),
      {} as Record<string, number>
    )
  }, [sendRfqParams, amount])

  const handleChangeLegRatio = useCallback(
    (instrumentName: string, ratio: number) => {
      onChangeRfqParams((sendRfqParams) => {
        const legIndex = sendRfqParams.legs.findIndex(
          (leg) => leg.instrument_name === instrumentName
        )
        if (legIndex === -1) {
          console.warn('invalid instrumentName in handleChangeLegRatio')
          return sendRfqParams
        }

        const baseAmount = getRfqGcdAmount(sendRfqParams.legs)
        const newLegs = [...sendRfqParams.legs]
        newLegs[legIndex].amount = (baseAmount * ratio).toString()

        return {
          ...sendRfqParams,
          legs: newLegs,
        }
      })
    },
    [onChangeRfqParams]
  )

  const handleChangeAmount = useCallback(
    (_amount: number) => {
      // IMPORTANT!! do not let amount be set to 0, always default to 1
      const amount = _amount ? _amount : DEFAULT_RFQ_SIZE

      onChangeRfqParams((sendRfqParams) => {
        const currAmount = getRfqGcdAmount(sendRfqParams.legs)

        const newLegs = sendRfqParams.legs.map((leg) => ({
          ...leg,
          amount: (currAmount > 0 ? (+leg.amount * amount) / currAmount : amount).toString(),
        }))

        return {
          ...sendRfqParams,
          legs: newLegs,
        }
      })
    },
    [onChangeRfqParams]
  )

  const handleChangeCustomRatio = useCallback(
    (isCustomRatio: boolean) => {
      if (isCustomRatio) {
        setIsDefaultCustomRatio(true)
      } else {
        onChangeRfqParams((sendRfqParams) => {
          const currAmount = getRfqGcdAmount(sendRfqParams.legs)

          // reset ratios to amount
          const newLegs = sendRfqParams.legs.map((leg) => ({
            ...leg,
            amount: currAmount.toString(),
          }))

          return {
            ...sendRfqParams,
            legs: newLegs,
          }
        })
        setIsDefaultCustomRatio(false)
      }
    },
    [onChangeRfqParams]
  )

  // Fee is accounted for in quote
  const estMarginRequired = bestQuoteEstimate
    ? +bestQuoteEstimate.pre_initial_margin - +bestQuoteEstimate.post_initial_margin
    : 0

  const handleSendRfq = useCallback(async () => {
    setSendRfqError(undefined)

    try {
      if (!bestQuoteEstimate) {
        throw new Error('No best quote')
      }

      if (!subaccount) {
        throw new Error('No subaccount')
      }

      if (!tickers) {
        throw new Error('Tickers are not loaded')
      }

      const indexPrice = +Object.values(tickers)[0].index_price

      const sendRfqResult = await sendRfq({
        ...sendRfqParams,
        max_total_cost: getRfqMaxTotalCost(sendRfqParams, bestQuoteEstimate, indexPrice).toString(),
        subaccount_id: subaccount.subaccount_id,
      })

      onSendRfq(sendRfqResult)
    } catch (error) {
      if (error instanceof Error) {
        setSendRfqError(error)
      }
      console.error(error)
    }
  }, [bestQuoteEstimate, subaccount, tickers, sendRfq, sendRfqParams, onSendRfq])

  return (
    <>
      {sendRfqParams.legs.map((leg) => (
        <SelectedOptionRow
          key={leg.instrument_name}
          instrumentName={leg.instrument_name}
          isBuy={leg.direction === 'buy'}
          isEditingRatio={isDefaultCustomRatio}
          ratio={legRatios[leg.instrument_name]}
          showRatio={isCustomRatio}
          onChangeRatio={(ratio) => handleChangeLegRatio(leg.instrument_name, ratio)}
          price={
            tickers
              ? getDefaultLimitPrice(tickers[leg.instrument_name], leg.direction === 'buy') ?? 0
              : 0
          }
          showPriceSign
          onDeselect={() =>
            onDeselectOption({
              instrumentName: leg.instrument_name,
              isBuy: leg.direction === 'buy',
            })
          }
        />
      ))}
      {sendRfqParams.legs.length <= 1 ? <OptionsTradeFormMultiSelectRow /> : null}
      <Section.XStack justifyContent="space-between" alignItems="center">
        <YStack>
          <BodyText>Size</BodyText>
          {sendRfqParams.legs.length > 1 ? (
            <BodyText color="secondary">
              <Link
                label={isCustomRatio ? 'Use Default' : 'Use Ratio'}
                onPress={() => handleChangeCustomRatio(!isCustomRatio)}
              />
            </BodyText>
          ) : null}
        </YStack>
        <NumberInput onChangeValue={handleChangeAmount} value={amount} width="50%" />
      </Section.XStack>
      <Section.Separator />
      <OptionsRfqTicketRows quote={bestQuoteEstimate} isEstimate isLoading={isLoading} />
      <TradeExecuteButtonRow
        isInsufficientBalance={!!bestQuoteEstimate && !bestQuoteEstimate.is_valid}
        insufficientBalanceMessage={bestQuoteEstimate?.invalid_reason}
      >
        {error ? <TradeErrorNotice error={error} /> : null}
        <Button
          width="100%"
          label="Request Quote"
          isDisabled={!bestQuoteEstimate?.is_valid}
          size="lg"
          color="cta"
          isOutlined
          onPress={handleSendRfq}
        />
      </TradeExecuteButtonRow>
      <Section.Separator />
      <OptionsRfqReceiptRows
        rfq={sendRfqParams}
        tickers={tickers}
        quote={bestQuoteEstimate}
        isLoading={isLoading}
      />
    </>
  )
}

export default OptionsRfqSendSection
