import { useContext, useEffect, useMemo, useRef } from 'react'

import { Ticker } from '../constants/instruments'
import { TickerContext } from '../providers/TickerProvider'

// Note: ensure instrumentNames array is memoized
export default function useTickers(instrumentNames: string[]): Record<string, Ticker> | undefined {
  const { isReady, subscribeTickers, unsubscribeTickers, tickers } = useContext(TickerContext)

  const newInstrumentNames = useRef(instrumentNames)
  newInstrumentNames.current = instrumentNames

  const prevInstrumentNames = useRef<string[]>([])

  useEffect(() => {
    if (isReady) {
      const newInstrumentNames = instrumentNames.filter(
        (name) => !prevInstrumentNames.current.includes(name)
      )
      if (newInstrumentNames.length) {
        subscribeTickers(newInstrumentNames)
      }
      prevInstrumentNames.current = instrumentNames
    }
    return () => {
      if (isReady) {
        const removedInstrumentNames = instrumentNames.filter(
          (name) => !newInstrumentNames.current.includes(name)
        )
        if (removedInstrumentNames.length) {
          // console.debug('remove tickers', removedInstrumentNames)
          unsubscribeTickers(removedInstrumentNames)
        }
      }
    }
  }, [isReady, instrumentNames, subscribeTickers, unsubscribeTickers])

  useEffect(() => {
    return () => {
      if (isReady) {
        if (newInstrumentNames.current.length) {
          // console.debug('unmount tickers', newInstrumentNames.current)
          unsubscribeTickers(newInstrumentNames.current)
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const tickersForInstrumentNames: Record<string, Ticker> = useMemo(
    () =>
      instrumentNames.reduce(
        (map, instrumentName) =>
          tickers[instrumentName]
            ? {
                ...map,
                [instrumentName]: tickers[instrumentName],
              }
            : map,
        {}
      ),
    [instrumentNames, tickers]
  )

  return useMemo(() => {
    const isLoading = !instrumentNames.every((name) => !!tickersForInstrumentNames[name])
    if (isLoading) {
      // tickers map only returns when all tickers are loaded or timeout occurs
      return undefined
    } else {
      return tickersForInstrumentNames
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tickersForInstrumentNames, instrumentNames])
}
