import Button from '@lyra/core/components/Button'
import Notice from '@lyra/core/components/Notice'
import Section from '@lyra/core/components/Section'
import useAuth from '@lyra/web/hooks/useAuth'
import useSubaccount from '@lyra/web/hooks/useSubaccount'
import useTransaction from '@lyra/web/hooks/useTransaction'
import { getTransactionDisabledMessage } from '@lyra/web/utils/wallet'
import React from 'react'

import EnableTradingButtonRow from '../common/EnableTradingButtonRow'
import FundTradingButtonRow from '../common/FundTradingButtonRow'
import SignInButton from '../common/SignInButton'

type Props = {
  isInsufficientBalance?: boolean
  insufficientBalanceMessage?: string | null
  children?: React.ReactNode
}

const TradeExecuteButtonRow = ({
  children,
  isInsufficientBalance,
  insufficientBalanceMessage,
}: Props) => {
  const { isEmpty: subaccountIsEmpty } = useSubaccount()

  const { isAuthenticated, accountDisabledReason } = useAuth()
  const { sessionKey } = useTransaction()

  if (!isAuthenticated) {
    return (
      <Section.YStack>
        <SignInButton isCta size="lg" width="100%" />
      </Section.YStack>
    )
  }

  if (accountDisabledReason) {
    return (
      <Section.YStack>
        <Notice
          message={getTransactionDisabledMessage(accountDisabledReason)}
          status="error"
          width="100%"
        />
        <Button isDisabled size="lg" width="100%" label="Trading Disabled" />
      </Section.YStack>
    )
  }

  if (subaccountIsEmpty || isInsufficientBalance) {
    return <FundTradingButtonRow message={insufficientBalanceMessage} width="100%" />
  }

  if (!sessionKey) {
    return <EnableTradingButtonRow width="100%" />
  }

  return <Section.YStack>{children}</Section.YStack>
}

export default TradeExecuteButtonRow
